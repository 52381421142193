import styled from "styled-components";

export const Container = styled.div`
    
`;


export const BoxInputs = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
`

export const BoxLineInputs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 18px;
`
