import { Button } from "@mui/material";
import { HeaderTitle } from "../../components/HeaderTitle";
import { Steps } from "../../components/Steps";
import { BoxButtons, BoxInButton, Container, ContainerInputs } from "./styles";
import { Icon } from "../../Icons";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { CreateAccountProps, StepsEnum } from "./types";
import { BoxCreateAccountCompany } from "../../components/BoxCreateAccountCompany";
import { BoxCreateAccountAccess } from "../../components/BoxCreateAccountAccess";
import { BoxMessage } from "../../components/BoxMessage";
import { BoxCreateAccountCompanyFormValues } from "../../components/BoxCreateAccountCompany/types";
import { BoxCreateAccountAccessFormValues } from "../../components/BoxCreateAccountAccess/types";
import { ListErrorsYup } from "../../utils";
import { LoadingButton } from "@mui/lab";
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { RoutesFamilyEnum, useRouterFamilyNavigation } from "../../hooks/router-custom";
import { useApi } from "../../hooks/ApiContext";

export const CreateAccount: React.FC<CreateAccountProps> = () => {
  const { ExistSlug, CreateCompany, signin, GetApplicationsByEmail, slug } = useApi();
  const navigate = useRouterFamilyNavigation();
  const [createSuccess, setCreateSuccess] = useState(false);
  const [validating, setValidating] = useState(false);
  const [stepsRun, setStepsRun] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [formAccount, setFormAccount] = useState<BoxCreateAccountCompanyFormValues>({ company: '', document: '', category: 'Salão de Beleza', url: '' });
  const [formAccess, setFormAccess] = useState<BoxCreateAccountAccessFormValues>({ name: '', phone: '', email: '', password: '' });
  const [formErrorAccount, setFormErrorAccount] = useState<BoxCreateAccountCompanyFormValues>({ company: '', document: '', category: '', url: '' });
  const [formErrorAccess, setFormErrorAccess] = useState<BoxCreateAccountAccessFormValues>({ name: '', phone: '', email: '', password: '' });

  const validationSchemaCreateCompany = yup.object().shape({
    document: yup
      .string()
      .required('O campo de CPF/CNPJ é obrigatório')
      .test('cpfOrCnpj', 'CPF ou CNPJ inválido', (value) => {
        const cleanedValue = value.replace(/\D/g, '');    
        if (cleanedValue.length === 11) {
          return cpf.isValid(cleanedValue);
        } else if (cleanedValue.length === 14) {
          return cnpj.isValid(cleanedValue);
        }    
        return false;
      }),
    company: yup
      .string()
      .required('O campo de Nome é obrigatório')
      .min(3, 'O nome de Nome deve ter pelo menos 3 caracteres'),
    category: yup
      .string()
      .required('O campo de Nome é obrigatório')
      .min(3, 'O nome de Nome deve ter pelo menos 3 caracteres'),
    url: yup
      .string()
      .required('O campo de URL é obrigatório')
      .min(3, 'O nome de URL deve ter pelo menos 3 caracteres'),
  });

  const validationSchemaCreateAccess = yup.object().shape({
    name: yup
      .string()
      .required('O campo de Nome é obrigatório')
      .min(3, 'O nome de Nome deve ter pelo menos 3 caracteres'),
    password: yup
      .string()
      .required('O campo de senha é obrigatório')
      .min(3, 'A senha deve ter pelo menos 3 caracteres'),
    email: yup
      .string()
      .email()
      .required('O campo de Email é obrigatório')
      .min(3, 'O nome de Email deve ter pelo menos 3 caracteres'),
    phone: yup
      .string()
      .required('O campo de Celular é obrigatório')
      .min(3, 'O nome de Celular deve ter pelo menos 10 caracteres'),
  });

  const handleNext = async () => {
    if (stepsRun === StepsEnum.CreateCompany) {
      setValidating(true);
      try {
        validationSchemaCreateCompany.validateSync(formAccount, { abortEarly: false });
      } catch (e: any) {
        const allErrors = ListErrorsYup(e);
        const newFormErrorAccount: BoxCreateAccountCompanyFormValues = { company: '', document: '', category: '', url: '' };
        for (const key in allErrors) {
          const nomeado = allErrors[key]?.name as string;
          if (nomeado in newFormErrorAccount) {
            Object.defineProperty(newFormErrorAccount, nomeado, {
              value: allErrors[key].error,
              writable: true,
              enumerable: true,
              configurable: true
            });
          }
        }
        setFormErrorAccount(newFormErrorAccount);
        setValidating(false);
        return;
      }

      const resopnseSlug = await ExistSlug(formAccount.url);
      if (resopnseSlug.original.data?.document !== undefined) {
        setFormErrorAccount({ company: '', document: '', category: '', url: 'Essa URL já está em uso' });
        setValidating(false);
        return;
      }

      setValidating(false);
    } if (stepsRun === StepsEnum.CreateAccount) {
      setValidating(true);
      try {
        validationSchemaCreateAccess.validateSync(formAccess, { abortEarly: false });
      } catch (e: any) {
        const allErrors = ListErrorsYup(e);
        const newFormErrorAccess: BoxCreateAccountAccessFormValues = { name: '', phone: '', email: '', password: '' };
        for (const key in allErrors) {
          const nomeado = allErrors[key]?.name as string;
          Object.defineProperty(newFormErrorAccess, nomeado, {
            value: allErrors[key].error,
            writable: true,
            enumerable: true,
            configurable: true
          });
        }
        setFormErrorAccess(newFormErrorAccess);
        setValidating(false);
        return;
      }

      const payload = {
        name: formAccount.company,
        document: formAccount.document,
        category: formAccount.category,
        slug: formAccount.url,
        phone: formAccess.phone,
        email: formAccess.email,
        responsible: formAccess.name,
        user: {
          name: formAccess.name,
          email: formAccess.email,
          phone: formAccess.phone,
          password: formAccess.password,
        }
      };
      const response = await CreateCompany(payload);
      if (response.original?.errors && response.original?.errors?.length > 0) {
        setErrors(response.original?.errors);
        setValidating(false);
        return;
      }

      await GetApplicationsByEmail(formAccess.email);

      const responseLogin = await signin(formAccess.email, formAccess.password );
      if (responseLogin.data) {
        setCreateSuccess(true);        
      } else {
        setErrors(responseLogin.errors);
        setValidating(false);
      }
    } else {
      setStepsRun(stepsRun + 1);
    }
  }

  const handlePrior = (e: any) => {
    if (stepsRun === 0) {
      navigate(RoutesFamilyEnum.Login);
    } else {
      setStepsRun(stepsRun - 1);
    }
  }

  useEffect(()=>{
    if (createSuccess){
      navigate(RoutesFamilyEnum.DataAnalysis);
    }    
  },[slug,createSuccess, navigate]);

  const allSteps = [
    {
      active: stepsRun === 0,
      title: "Empresa",
      subTitle: "Crie sua conta",
      icon: "house",
      header: "Dados da sua empresa",
      subHeader: "Preencha os dados abaixo para criar a conta da sua empresa",
      boxScreen: () => { return <BoxCreateAccountCompany formErrors={formErrorAccount} formValue={formAccount} onFormValues={(v: BoxCreateAccountCompanyFormValues) => setFormAccount(v)} /> }
    },
    {
      active: stepsRun === 1,
      title: "Usuário",
      subTitle: "Suas Informações",
      icon: "user-icon",
      header: "Dados de acesso",
      subHeader: "Preencha os dados abaixo para criar o seu usuário",
      boxScreen: () => { return <BoxCreateAccountAccess formErrors={formErrorAccess} formValue={formAccess} onFormValues={(v: BoxCreateAccountAccessFormValues) => setFormAccess(v)} /> }
    },
    //{ active: stepsRun === 2, title: "Pagamento", subTitle: "Selecione o Plano", icon: "credit-card" }
  ];

  return (
    <Container>


      <img
        src="./woman-office.png"
        alt="login sprite"
        width={"25%"}
      />

      <ContainerInputs>
        <Steps
          items={allSteps}
        />
        <HeaderTitle >{allSteps[stepsRun].header}</HeaderTitle>
        <HeaderTitle sm="true" >{allSteps[stepsRun].subHeader}</HeaderTitle>
        {allSteps[stepsRun].boxScreen()}
        <BoxButtons>
          <Button
            sx={{ textTransform: 'none' }}
            style={{ width: '150px' }}
            variant="outlined"
            onClick={handlePrior}
          >
            <BoxInButton>
              <Icon name={"left-arrow-step"} width={14} height={14} color={"#1976d2"} />
              Voltar
            </BoxInButton>
          </Button>
          <LoadingButton
            sx={{ textTransform: 'none' }}
            style={{ width: '150px' }}
            variant="contained"
            onClick={handleNext}
            loading={validating}
            loadingPosition="end"
            disabled={validating}
          >
            <BoxInButton>
              Próximo
              <Icon name={"right-arrow-step"} width={14} height={14} color={"white"} />
            </BoxInButton>
          </LoadingButton>
        </BoxButtons>
        <BoxMessage active={errors.length > 0} text={errors.join("\r")} color="red" />
      </ContainerInputs>

    </Container>
  );
};
