import ComboBoxInput from "../ComboBoxInput";
import TextBoxInput from "../TextBoxInput";
import { UploadCompany } from "../UploadCompany";
import { Container } from "./styles";
import { BoxDataCompanyProps } from "./types";
import { Button } from "@mui/material";

export const BoxDataCompany: React.FC<BoxDataCompanyProps> = ({ handleInput, ...attrs }) => {
  return (
    <Container {...attrs}>
      <UploadCompany />
      <TextBoxInput
        label="Nome da Empresa"
        onChange={handleInput}
        type="text"
        subLabel=""
        placeHolder="Nome da Empresa"
      />
      <ComboBoxInput
        label="Categoria"
        //onChange={handleInput}
        items={[{label:"valor 1",value:"val-1"},{label:"valor 2",value:"val-2"},{label:"valor 3",value:"val-3"},]}
      />
      <TextBoxInput
        label="CPF/CNPJ"
        onChange={handleInput}
        type="text"
        subLabel=""
        placeHolder="00.000.000/0001-00"
      />
      <TextBoxInput
        label="Responsável"
        onChange={handleInput}
        type="text"
        subLabel=""
        placeHolder="Nome Responsável"
      />
      <TextBoxInput
        label="E-mail"
        onChange={handleInput}
        type="email"
        subLabel=""
        placeHolder="responsavel@empresa.com"
      />
      <TextBoxInput
        label="Telefone"
        onChange={handleInput}
        type="text"
        subLabel=""
        placeHolder="(00) 00000-0000"
      />
      <Button
        sx={{ my: 2, textTransform: 'none' }}
        style={{ background: '#1090D2', width: '100%' }}
        type='submit'
        variant="contained"
      >
        Salvar
      </Button>
    </Container>
  );
};
