import { useState } from "react";
import { ButtonMenu } from "../ButtonMenu";
import { Container } from "./styles";
import { MenuProps, itemMenu } from "./types";
import { useRouterLocation, useRouterFamilyNavigation, RoutesFamilyEnum } from "../../hooks/router-custom";

export const Menu: React.FC<MenuProps> = ({ children, ...attrs }) => {
  const navigate = useRouterFamilyNavigation();
  const location = useRouterLocation();

  const itemsMenu: Array<itemMenu> = [
    { icon: "disconnect", label: "Conectores", route: RoutesFamilyEnum.Connects },
    //{ icon: "global", label: "Fontes Externas", route: RoutesFamilyEnum.ExternalFonts },
    //{ icon: "diagram", label: "Modelagem", route: RoutesFamilyEnum.Modeling },
    { icon: "chart", label: "Analise de Dados", route: RoutesFamilyEnum.DataAnalysis },
    //{ icon: "circle-family", label: "Segmentação", route: RoutesFamilyEnum.Segmentation },
    //{ icon: "mind", label: "Inteligência", route: RoutesFamilyEnum.Intelligence },
    { icon: "config", label: "Minha Conta", route: RoutesFamilyEnum.Account },
  ]

  const defaultStartButton = itemsMenu.filter(i=>{
    return location.pathname.indexOf(i.route) > -1
  });

  const [active, setActive] = useState(defaultStartButton.length>0?defaultStartButton[0].icon:'');


  const handleClick = (item: itemMenu) => {
    setActive(item.icon);
    navigate(item.route);
  }

  return (
    <Container {...attrs}>
      {itemsMenu.map((item,idx) => {
        return <ButtonMenu key={idx} click={() => handleClick(item)} active={active === item.icon} icon={item.icon}>{item.label}</ButtonMenu>
      })}
    </Container>
  );
};
