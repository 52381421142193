import { HeaderTitle } from "../HeaderTitle";
import TextBoxInput from "../TextBoxInput";
import { Container } from "./styles";
import { BoxDataUserProps } from "./types";
import { Button } from "@mui/material";

export const BoxDataUser: React.FC<BoxDataUserProps> = ({ handleInput, ...attrs }) => {
  return (
    <Container {...attrs}>
        <HeaderTitle sm={"true"} pad={"bottom"}>Dados do seu usuário</HeaderTitle>
        <TextBoxInput
          label="Nome Completo"
          onChange={handleInput}
          type="text"
          subLabel=""
          placeHolder="Nome Completo"
        />   
        <TextBoxInput
          label="CPF"
          onChange={handleInput}
          type="text"
          subLabel=""
          placeHolder="000.000.000-00"
        />      
        <TextBoxInput
          label="E-mail"
          onChange={handleInput}
          type="email"
          subLabel=""
          placeHolder="responsavel@empresa.com"
        />   
        <TextBoxInput
          label="Celular"
          onChange={handleInput}
          type="text"
          subLabel=""
          placeHolder="(00) 00000-0000"
        />      
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#1090D2', width: '100%' }}
          type='submit'
          variant="contained"
        >
          Salvar
        </Button>    
        <HeaderTitle sm={"true"} pad={"bottom"}>Alterar Senha</HeaderTitle>
        <TextBoxInput
          label="Senha Antiga"
          onChange={handleInput}
          type="password"
          subLabel=""
          placeHolder="******"
        />   
        <TextBoxInput
          label="Senha Nova"
          onChange={handleInput}
          type="password"
          subLabel=""
          placeHolder="******"
        />   
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#1090D2', width: '100%' }}
          type='submit'
          variant="contained"
        >
          Alterar Senha
        </Button>                                     
    </Container>
  );
};
