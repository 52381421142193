"use client"

import { Container, ContainerLogo, LogoMarca } from './styles';
import { LoginProps } from './types';
import { BoxMainLogin } from '../../components/BoxMainLogin';

export const MainScreen: React.FC<LoginProps> = () => {
  return (
    <Container>
      <ContainerLogo>
        <LogoMarca>
          <div style={{ height: '71.41px' }}>
            <img
              src='./conectabi-logo.png'
              alt="login sprite"
            />
          </div>

        </LogoMarca>
        <div className='container-login-image'>
          <img
            src="./login-sprite.png"
            alt="login sprite"
          />
        </div>

      </ContainerLogo>        
        <BoxMainLogin />
    </Container>
  )
}
