import { Container, LabelAccordion, StyledCheckbox, StyledCheckboxInactive } from "./styles";
import { CheckBoxProps } from "./types";

export const CheckBox: React.FC<CheckBoxProps> = ({ text, checked, onChecked }) => {
  return (
    <Container onClick={()=>onChecked()} >
       { checked ? <StyledCheckbox /> : <StyledCheckboxInactive /> }
        <LabelAccordion onClick={()=>onChecked()} > {text}</LabelAccordion>
    </Container>
  );
};
