import { Icon } from "../../Icons";
import { Container } from "./styles";

export const ImageCompany: React.FC = ({ ...attrs }) => {
  return (
    <Container {...attrs}>
      <Icon name={"house"} color={"#1090D2"} />                       
    </Container>
  );
};
