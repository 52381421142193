import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useApi } from "./ApiContext";

export enum RoutesFamilyEnum {
    Connects = "connects",
    SelectConnection = "select-connection",    
    Main ="" ,
    CreateAccount ="create-account" , 
    Login ="login" ,
    ExternalFonts ="external-fonts",              
    Modeling ="modeling" ,
    Segmentation ="segmentation" ,
    Intelligence ="intelligence" ,
    Account ="account" ,
    ManageConnection ="manage-connection" ,   
    DataAnalysis ="data-analysis" ,
}

export const useRouterNavigation = () => {
    const navigate = useNavigate();
    return (url:string) => {
        return navigate(url)
    }
};

export const useRouterFamilyNavigation = () => {
    const { slug } = useApi();
    const navigate = useNavigate();
    return (url:RoutesFamilyEnum,parameters?:string) => {
        const route = url === RoutesFamilyEnum.CreateAccount ? "" : "/"+slug;
        return navigate(`${route}/${url.toString()}${parameters?'/'+parameters:''}`)
    }
};

export const useRouterParams = (item:string) => {
    const params = useParams();
    return params[item];    
};

export interface PathRouterCustom {
    /**
     * A URL pathname, beginning with a /.
     */
    pathname: string;
    /**
     * A URL search string, beginning with a ?.
     */
    search: string;
    /**
     * A URL fragment identifier, beginning with a #.
     */
    hash: string;
}

export const useRouterLocation = () : PathRouterCustom => {
    const loc = useLocation();
    return loc;    
};
