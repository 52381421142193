import { Button } from "@mui/material";
import { SubContainerLogin, ContainerLogin, SubTitle, Title } from "./styles";
import { BoxLoginProps } from "./types";
import TextBoxInput from "../TextBoxInput";
import { useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { BoxMessage } from "../BoxMessage";
import { RoutesFamilyEnum, useRouterFamilyNavigation } from "../../hooks/router-custom";
import { useApi } from "../../hooks/ApiContext";

export const BoxLogin: React.FC<BoxLoginProps> = ({ createAccount, eventBox }) => {
  const { signin } = useApi();
  const navigate = useRouterFamilyNavigation();
  const [validating, setValidating] = useState(false);  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);

  const enter = async () => {    
    try{
      setValidating(true);
      const response = await signin(email,password);
      if (response.data){
        navigate(RoutesFamilyEnum.DataAnalysis);
      }else{
        setValidating(false);
        setErrors(response.errors);        
      }
    } catch (e) {
      console.log(e)
    }
  }

  // const createAccount = async () => {    
  //   //navigate("/create-account");
  // }

  return (
    <ContainerLogin>
      <SubContainerLogin>
        <Title className='h1-title'>Bem vindo! 👋🏻</Title>
        <SubTitle>Entre em sua conta ou cadastre-se para começar</SubTitle>

        <TextBoxInput
          label="Email"
          onChange={e => setEmail(e.target.value)}
          type="email"
          value={email}
          subLabel=""
          placeHolder="john@gmail.com"
        />
        <TextBoxInput
          type="password"
          label="Senha"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeHolder="*********"
          subLabel="Esqueceu a Senha?"
          onClickRight={(p: any) => eventBox('forgot')}
        />

        <LoadingButton
            loading={validating}
            loadingPosition="end"
            disabled={validating || !email || !password}
            variant="contained"
            sx={{ my: 2, textTransform: 'none' }}
            style={{ background: '#1090D2', width: '100%' }}
            type="submit"
            onClick={enter}
            fullWidth
          >
            {validating ? "Aguarde..." : "Entrar"}
          </LoadingButton>        

        <Button
          sx={{ textTransform: 'none' }}
          style={{ width: '100%' }}
          variant="outlined"
          onClick={createAccount}
        >
          Cadastre-se
        </Button>
        <BoxMessage active={errors.length>0} text={errors.join("\r")} color="red" />
      </SubContainerLogin>
    </ContainerLogin>
  );
};
