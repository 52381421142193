import styled from "styled-components";

export const Container = styled.div`
    background: rgb(248, 248, 248);
    display: grid;
    grid-template-columns: 1fr 600px;
    height: 100vh;
`

export const ContainerLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ContainerLogin = styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
`

export const LogoMarca = styled.div`
    position: absolute;
    left: 40px;
    top: 40px;
    display: flex;
`

export const SubContainerLogin = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
`

export const Title = styled.h1`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
    color:#5E5873;
`

export const SubTitle = styled.p`
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 50px;
    color:#6E6B7B;
`