import { Icon } from "../../Icons";
import { BoxDataCompany } from "../../components/BoxDataCompany";
import { BoxDataUser } from "../../components/BoxDataUser";
import { HeaderTitle } from "../../components/HeaderTitle";
import { TabBar } from "../../components/TabBar";
import { useApi } from "../../hooks/ApiContext";
import { RoutesFamilyEnum, useRouterFamilyNavigation } from "../../hooks/router-custom";
import { BoxHeader, Container, ExitTitle } from "./styles";

export const Account: React.FC = () => {
  const { signout } = useApi();
  const navigate = useRouterFamilyNavigation();
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

  };

  const logOut = () => {
    signout();
    navigate(RoutesFamilyEnum.Login); 
  }

  return (
    <Container>
      <HeaderTitle><BoxHeader>Minha Conta<ExitTitle onClick={logOut}><Icon name="logout" color="red"/> Sair</ExitTitle></BoxHeader></HeaderTitle>
      <TabBar items={[
        {
          label: 'Dados da Empresa',
          render: (index: number) => <BoxDataCompany key={index} handleInput={handleChange} />
        },
        {
          label: 'Meus Dados',
          render: (index: number) => <BoxDataUser key={index} handleInput={handleChange}/>
        },
        {
          label: 'Usuários',
          render: (index: number) => <div key={index}>comunidade</div>
        },
        {
          label: 'Faturas',
          render: (index: number) => <div key={index}>comunidade</div>
        },
        {
          label: 'Plano e Pagamento',
          render: (index: number) => <div key={index}>comunidade</div>
        }
      ]} ></TabBar>
    </Container>
  );
};
