import styled from "styled-components";

export const Container = styled.div`
    background: white;
    height: 107px;
    width: 232px;
    border-radius : 5px;   
    border: 1px solid #EBE9F1;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:hover{
        border: solid 1px #7367F0;
    }
`;

export const ContainerInactive = styled.div`
    background: white;
    height: 107px;
    width: 232px;
    border-radius : 5px;   
    border: 1px solid #EBE9F1;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #5E5873;
    margin: 0px;
`

export const Detail = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 21px;
    color: #A5A5A5;    
    margin: 0px 25px 10px 25px;    
`

export const BoxImageTitle = styled.div`
    display: grid;
    grid-template-columns: 50px 1fr;
    margin: 15px 25px 15px 25px;
`

export const BoxTitleTag = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px;
`