
import { HeaderDetail } from "../../components/HeaderDetail";
import { HeaderTitle } from "../../components/HeaderTitle";
import { TabBar } from "../../components/TabBar";
import { Container } from "./styles";

export const Intelligence: React.FC = () => {
  
  const items = () => {
    return [
      {
        label: 'Indicadores pelo Whatsapp',
        render: (index:number) => <div key={index}>meus</div>
      },
      {
        label: 'Números Autorizados',
        render: (index:number) => <div key={index}>comunidade</div>
      }
    ]
  }

  return (
    <Container>
    <HeaderTitle>Inteligência</HeaderTitle>
    <HeaderDetail>Faça perguntas para a Inteligência Artificial da Conecta.BI, sobre os seus dados e receba indicadores em tempo real. Crie Dashboards, Relatórios e programe campanhas e recados diários com poucas instruções. Você pode interagir com o chat ao lado ou com nosso número no Whatsapp.</HeaderDetail>
    <TabBar items={items()} ></TabBar> 
    </Container>
  );
};
