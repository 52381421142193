
import { Icon } from "../../Icons";
import { BoxConnection } from "../../components/BoxConnection";
import { HeaderTitle } from "../../components/HeaderTitle";
import { AlignBoxTitleHeaderDrawer, Container, ContainerColumn, ContainerDrawer, ContainerHeaderArrow, ContainerHeaderDrawer, ContainerIcon, ContainerInsideAccordion } from "./styles";
import { RoutesFamilyEnum, useRouterFamilyNavigation } from "../../hooks/router-custom";
import { Accordion } from "../../components/Accordion";
import { Drawer, Link } from "@mui/material";
import { useState } from "react";
import { Color } from "../../components/BoxConnection/types";

export const SelectConnection: React.FC = () => {
  const navigate = useRouterFamilyNavigation();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Container>
      <ContainerHeaderArrow>
        <ContainerIcon onClick={() => navigate(RoutesFamilyEnum.ManageConnection)}>
          <Icon paddingDefault={false} name="arrow-back" color={"black"} />
        </ContainerIcon>
        <HeaderTitle>Selecione os Conectores</HeaderTitle>
      </ContainerHeaderArrow>
      <BoxConnection
        image={"https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/168_Hubspot_logo_logos-1024.png"}
        title={"HubSpot"}
        tag={{ text: "Tecnologia", color: Color["orange"] }}
        inactive={true}
      />
      <ContainerColumn>
        <Accordion items={[
          {
            label: "primeiro",
            value: "primeiro",
            render: () => {
              return <><p>Lorem ipsum dolor sit amet. Hic rerum quibusdam sed voluptatum eius aut animi repudiandae et exercitationem explicabo sed odio alias. Sed reiciendis maxime et possimus voluptas hic tempore amet est quisquam inventore. </p><p>Ad nemo consequatur ea voluptatem sint ea esse accusamus est perspiciatis vitae quo iusto obcaecati. Non natus quia ad consequatur tempore At suscipit inventore qui adipisci quia? 33 enim veritatis non dolores consequatur ab quia consectetur et veritatis dolore nam perferendis accusantium. </p><p>Et dignissimos voluptatem sit totam illum 33 voluptas temporibus. Sit laboriosam quibusdam in asperiores vero qui placeat porro aut ipsa totam qui possimus illo non illo velit. </p></>
            }
          },
          {
            label: "segundo",
            value: "primeiro",
            render: () => {
              return <><p>Lorem ipsum dolor sit amet. Hic rerum quibusdam sed voluptatum eius aut animi repudiandae et exercitationem explicabo sed odio alias. Sed reiciendis maxime et possimus voluptas hic tempore amet est quisquam inventore. </p><p>Ad nemo consequatur ea voluptatem sint ea esse accusamus est perspiciatis vitae quo iusto obcaecati. Non natus quia ad consequatur tempore At suscipit inventore qui adipisci quia? 33 enim veritatis non dolores consequatur ab quia consectetur et veritatis dolore nam perferendis accusantium. </p><p>Et dignissimos voluptatem sit totam illum 33 voluptas temporibus. Sit laboriosam quibusdam in asperiores vero qui placeat porro aut ipsa totam qui possimus illo non illo velit. </p></>
            }
          },
          {
            label: "terceiro",
            value: "primeiro",
            render: () => {
              return <ContainerInsideAccordion>
                <HeaderTitle sm={"true"}>Frequência de Atualização:</HeaderTitle>
                <p>1 vez por dia as 01:00</p>
                <Link style={{ cursor: "pointer" }} onClick={() => { setOpenDrawer(true) }}>
                  Alterar Frequencia
                </Link>
              </ContainerInsideAccordion>
            }
          },
        ]} />
      </ContainerColumn>
      <Drawer
        anchor={"right"}
        open={openDrawer}
      >
        <ContainerDrawer>
          <ContainerHeaderDrawer>
            <ContainerIcon onClick={() => setOpenDrawer(false)}>
              <Icon color="black" name="close-x" />
            </ContainerIcon>
            <AlignBoxTitleHeaderDrawer>
              <HeaderTitle md={"true"}>Frequência de coleta de dados</HeaderTitle>
            </AlignBoxTitleHeaderDrawer>
          </ContainerHeaderDrawer>
        </ContainerDrawer>
      </Drawer>
    </Container>
  );
};
