import styled from "styled-components";

export const Container = styled.div`
    background: rgb(248, 248, 248);
    display: grid;
    grid-template-columns: 1fr 600px;
    height: 100vh;
`

export const ContainerLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const LogoMarca = styled.div`
    position: absolute;
    left: 40px;
    top: 40px;
    display: flex;
`
