
import { Button } from "@mui/material";
import { Icon } from "../../Icons";
import { BoxConnection } from "../../components/BoxConnection";
import { HeaderTitle } from "../../components/HeaderTitle";
import TextBoxInput from "../../components/TextBoxInput";
import { Container, ContainerColumn, ContainerHeaderArrow, ContainerIcon } from "./styles";
import { RoutesFamilyEnum, useRouterFamilyNavigation, useRouterParams } from "../../hooks/router-custom";
import { useApi } from "../../hooks/ApiContext";
import { memo, useState } from "react";
import { AuthParams } from "./types";

export const AddConnection: React.FC = () => {  
  const { getApplicationStorage } = useApi();  
  const navigate = useRouterFamilyNavigation();
  const nameConnection = useRouterParams('connetion');
  const [authentication, setAuthentication] = useState<AuthParams>({user:'',password:'',id:0,description:'',application_id:0,token:'',status:0,company_id:0});

  const setUser = (value:string) => {
    let newUser = {...authentication, user:value};
    setAuthentication(newUser);
  }

  const boxConnection = () => {
    let connector = { image:'',name:'',category:'',color:'' };
    const all = getApplicationStorage();
    const exists = all.filter(f => f.name === nameConnection);
    if (exists && exists.length>0){
      connector.image = String(exists[0].brand);
      connector.name = String(exists[0].name);
      connector.category = String(exists[0].category);
      connector.color = String(exists[0].color);
    }
    return (
      <BoxConnection
        image={connector.image}
        title={connector.name}
        tag={{text:connector.category,color:String(connector.color)}}
        inactive={true}
      />
    )
  }

  const BoxConnectionMemo = memo(boxConnection);

  return (
    <Container>
      <ContainerHeaderArrow>
        <ContainerIcon onClick={() => navigate(RoutesFamilyEnum.Connects)}>
          <Icon paddingDefault={false} name="arrow-back" color={"black"} />
        </ContainerIcon>
        <HeaderTitle>Nova Aplicação</HeaderTitle>
      </ContainerHeaderArrow>
      <BoxConnectionMemo />
      <ContainerColumn>        
        <HeaderTitle sm={"true"} pad={"bottom"}>Autenticação:</HeaderTitle>
        <TextBoxInput
          label="Usuário"
          onChange={e => setUser(e.target.value)}
          type="text"
          subLabel=""
          value={authentication?.user}
          placeHolder="Usuário"
        />
        <TextBoxInput
          label="Senha"
          onChange={() => console.log()}
          type="password"
          subLabel=""
          value={authentication?.password}
          placeHolder="*******"
        />
        <HeaderTitle sm={"true"} pad={"bottom"}>Parâmetros</HeaderTitle>
        <TextBoxInput
          label="Estabelecimento ID"
          onChange={() => console.log()}
          type="text"
          subLabel=""
          placeHolder="1234123423"
        />
        <TextBoxInput
          label="Nome Estabelecimento"
          onChange={() => console.log()}
          type="text"
          subLabel=""
          placeHolder="Mercado LTDA"
        />
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#1090D2', width: '100%' }}
          type='submit'
          variant="contained"
          onClick={()=>navigate(RoutesFamilyEnum.SelectConnection)}
        >
          Salvar e Testar Integração
        </Button>
      </ContainerColumn>
    </Container>
  );
};
