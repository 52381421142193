import styled from "styled-components";

export const Container = styled.div`
    background: white;
    display: flex;    
    height: 100vh;
`;

export const ContainerInputs = styled.div`
    width: 100%;
    padding: 50px 10% 50px 10%;    
`
export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
`

export const BoxInButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
`