
import { HeaderDetail } from "../../components/HeaderDetail";
import { HeaderTitle } from "../../components/HeaderTitle";
import { TabBar } from "../../components/TabBar";
import { Container } from "./styles";

export const Modeling: React.FC = () => {
    
  const items = () => {
    return [
      {
        label: 'Tabelas e Integrações',
        render: (index:number) => <div key={index}>meus</div>
      },
      {
        label: 'Modelagem',
        render: (index:number) => <div key={index}>comunidade</div>
      },
      {
        label: 'Bases de Dados',
        render: (index:number) => <div key={index}>comunidade</div>
      }
    ]
  }

  return (
    <Container>
    <HeaderTitle>Modelagem</HeaderTitle>
    <HeaderDetail>Crie relacionamento entre as suas tabelas, trate os dados coletados e ajuste as nomenclaturas dos campos para uma melhor leitura e entendimento das ferramentas de inteligência artificial e análise de dados. Você também pode especificar bases externas que devem ser alimentadas pelos conectores.</HeaderDetail>
    <TabBar items={items()} floatButtons={[{label:"Conectar nova base de dados"}]}></TabBar> 
    </Container>
  );
};
