import { Route, Routes, HashRouter, Navigate } from "react-router-dom";
import Layout from "./pages/Layout";
import { ReactElement } from "react";
import { Connects } from "./screens/connects";
import { ExternalFonts } from "./screens/externalFonts";
import { Modeling } from "./screens/modeling";
import { Segmentation } from "./screens/segmentation";
import { Intelligence } from "./screens/intelligence";
import { Account } from "./screens/account";
import { ManageConnection } from "./screens/manageConnection";
import { SelectConnection } from "./screens/selectConnection";
import { DataAnalysis } from "./screens/dataAnalysis";
import { Login } from "./screens/login";
import { CreateAccount } from "./screens/createAccount";
import { MainScreen } from "./screens/mainScreen";
import { useApi } from "./hooks/ApiContext";
import { AddConnection } from "./screens/addConnection";

export const RoutesApp: React.FC = () => {
  const { slug, getIsLogged } = useApi();

  const Renderizator = (comp: ReactElement, withMenu:boolean=true) => {
    return <Layout menu={withMenu}>{comp}</Layout>
  }
  
  const RoutesDefaultWithSlug = (defaultScreen:string) : string => {
    return slug ? `/${slug}/${defaultScreen}` : `/`;
  }
  
  const RoutesLogged = () => {
    return (
      <HashRouter>
        <Routes>                
          <Route path="/" element={(<MainScreen />)} />        
          <Route path="/create-account" element={(<CreateAccount/>)} />              
          <Route path="/:slug/connects" element={Renderizator(<Connects />)} />         
          <Route path="/:slug/external-fonts" element={Renderizator(<ExternalFonts/>)} />                    
          <Route path="/:slug/modeling" element={Renderizator(<Modeling/>)} />   
          <Route path="/:slug/segmentation" element={Renderizator(<Segmentation/>)} />   
          <Route path="/:slug/intelligence" element={Renderizator(<Intelligence/>)} />             
          <Route path="/:slug/account" element={Renderizator(<Account/>)} />      
          <Route path="/:slug/manage-connection/:connetion/new" element={(<AddConnection/>)} />      
          <Route path="/:slug/manage-connection/:connetion/edit" element={(<ManageConnection/>)} />      
          <Route path="/:slug/select-connection" element={(<SelectConnection/>)} />      
          <Route path="/:slug/data-analysis" element={Renderizator(<DataAnalysis/>)} />  
          <Route path="*" element={<Navigate to={RoutesDefaultWithSlug('data-analysis')} />}/>  
        </Routes>
      </HashRouter>
    );
  }
  
  
  const RoutesLogout = () => {
    return (
      <HashRouter>
        <Routes>                
          <Route path="/" element={(<MainScreen />)} />        
          <Route path="/create-account" element={(<CreateAccount/>)} />    
          <Route path="/:slug/login" element={(<Login />)} />    
          <Route path="*" element={<Navigate to={RoutesDefaultWithSlug('login')} />}/>
        </Routes>
      </HashRouter>
    );
  }

  return getIsLogged() ? RoutesLogged() : RoutesLogout();
};


export default RoutesApp;
