import './App.css';
import RoutesApp from './RoutesApp';
import ApiContext from './hooks/ApiContext';

function App() {
  return (
      <ApiContext>
        <RoutesApp/>
      </ApiContext>
  );
}

export default App;
