import { HeaderTitleProps } from "./types";
import { Title } from "./styles";

export const HeaderTitle: React.FC<HeaderTitleProps> = ({ pad, sm, md, children }) => {
  return (
    <Title 
      size={sm==="true"?"16px":md==="true"?"18px":"30px"} 
      spacing={pad === "bottom" ? "0px 0px 15px 0px" : pad === "top" ? "15px 0px 0px 0px" : "0px 0px 0px 0px"}      
      >
      {children}
    </Title>
  );
};
