import styled from "styled-components";
import { TitleProps } from "./types";

export const Title = styled.div<TitleProps>`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: ${p => p.size};
    line-height: 150%;
    color: #5B5B5B;
    padding: ${p => p.spacing};
`;