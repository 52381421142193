import { useState } from "react";
import TextBoxInput from "../TextBoxInput";
import { BoxInputs, BoxLineInputs, Container } from "./styles";
import { BoxCreateAccountAccessFormValues, BoxCreateAccountAccessProps } from "./types";

export const BoxCreateAccountAccess: React.FC<BoxCreateAccountAccessProps> = ({formErrors, formValue, onFormValues}) => {
    const [formValues, setFormValues] = useState<BoxCreateAccountAccessFormValues>({
        name: formValue.name,
        phone: formValue.phone,
        email:formValue.email,
        password:formValue.password,
      });
      
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        const newForm = {
            ...formValues,
            [name]: value,
        };
        setFormValues(newForm);
        onFormValues(newForm);
    };

    return (
        <Container>
            <BoxInputs>
                <BoxLineInputs>
                    <TextBoxInput
                        label="Seu nome completo"
                        type="text"
                        subLabel=""
                        placeHolder="John Wick"
                        name="name"                        
                        value={formValues["name"]}
                        onChange={handleChange}
                        error={formErrors["name"]}
                    />
                    <TextBoxInput
                        label="Celular"
                        type="text"
                        subLabel=""
                        placeHolder="(11) 9.9999-9999"
                        name="phone"                        
                        value={formValues["phone"]}
                        onChange={handleChange}
                        error={formErrors["phone"]}
                    />
                </BoxLineInputs>
                <BoxLineInputs>
                    <TextBoxInput
                        label="E-mail"
                        type="text"
                        subLabel=""
                        placeHolder="wick@email.com"
                        name="email"                        
                        value={formValues["email"]}
                        onChange={handleChange}
                        error={formErrors["email"]}
                    />
                    <TextBoxInput
                        label="Senha"
                        type="password"
                        subLabel=""
                        placeHolder="************"
                        name="password"                        
                        value={formValues["password"]}
                        onChange={handleChange}
                        error={formErrors["password"]}
                    />
                </BoxLineInputs>
            </BoxInputs>
        </Container>
    );
};
