import styled , { keyframes }  from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`

`;

export const TabButtonsContainer = styled.div`
    display: flex;
    height: 72px;
    align-items: end;
    justify-content: space-between;
    border-bottom: solid 1.37px #EAEAEF;
`

export const TabButtons = styled.div`
    display: flex;
`

export const TabButtonItem = styled.div`    
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;    
    display: flex;
    align-items: center;
    text-align: center;
    height: 48px;
    cursor: pointer;
    transition: border 0.5s;
`

export const TabItemRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
`

export const ItemContainer = styled.div`
    animation: ${fadeIn} 0.2s ease-in-out forwards;
`;