import styled from "styled-components"

export const ContainerDrawer = styled.div`
    padding: 30px 50px 50px 50px;
    width: 500px;
`

export const ContainerHeaderDrawer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`

export const AlignBoxTitleHeaderDrawer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

export const ContainerIcon = styled.div`
    cursor:pointer;
    margin-right:15px;
`