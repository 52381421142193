import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`

export const ContainerInputs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const InputBox = styled.input`
    width: 60px;
    text-align: center;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #A5A5A5;
    font-size: larger;
    color:#666666;
`
export const Title = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #5B5B5B;
    margin: 0px 0px 10px 0px;    
`

