import { Button, Link } from "@mui/material"
import { Accordion } from "../Accordion"
import { BoxDetailConnector, ContainerColumn } from "./styles"
import { BoxManagerConnectsProps, Connectors } from "./types"
import { DrawerRight } from "../DrawerRight"
import { memo, useState } from "react"
import { useApi } from "../../hooks/ApiContext"
import { BoxConnectorDrawer } from "../BoxConnectorDrawer"
import ComboBoxInput from "../ComboBoxInput"
import { ItemCombo } from "../ComboBoxInput/types"

export const BoxManagerConnects: React.FC<BoxManagerConnectsProps> = ({ connectors, nameConnection }) => {
  const { getApplicationStorage } = useApi();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [itemSelectTable, setItemSelectTable] = useState<Connectors>();
  const all = getApplicationStorage();

  const [allTimes] = useState<Array<ItemCombo>>([
    { label: "1 vez por dia", value: "DAILY" },
    { label: "Em um intervalo de Horas", value: "HOUR" },
    { label: "1 vez por semana", value: "WEEKLY" },
    { label: "1 vez por mês", value: "MONTH" }
  ]);

  const allHours = Array.from({length:24}, (e, i)=>{ 
    return {label:i.toString().padStart(2,'0')+` hora${i!==1?'s':''}`, value:i.toString()};
  });

  const boxConnection = () => {
    let connector = { image: '', name: '', category: '', color: '' };
    const exists = all.filter(f => f.name === nameConnection);
    if (exists && exists.length > 0) {
      connector.image = String(exists[0].brand);
      connector.name = String(exists[0].name);
      connector.category = String(exists[0].category);
      connector.color = String(exists[0].color);
    }
    return (
      <BoxConnectorDrawer
        image={connector.image}
        title={"Conector"}
        detail={`${connector.name} > ${itemSelectTable?.name}`}
      />
    )
  }

  const BoxConnectionMemo = memo(boxConnection);

  const TranslateFrequence = (item: Connectors) => {
    let result = "";
    if (item.default_type_repeat === "DAILY") {
      result = "1 vez por dia ás " + item.default_hour.toString().padStart(2, "0") + ":00";
    } else if (item.default_type_repeat === "HOUR") {
      result = "A cada " + item.default_hour.toString().padStart(2, "0") + " hora(s)";
    } else if (item.default_type_repeat === "WEEKLY") {
      result = "Toda Semana no dia " + item.default_day?.toString() + " ás " + item.default_hour.toString().padStart(2, "0") + ":00";
    } else if (item.default_type_repeat === "MONTH") {
      result = "Toda Mês no dia " + item.default_day?.toString() + " ás " + item.default_hour.toString().padStart(2, "0") + ":00";
    }
    return result;
  }

  const createItens = () => {
    return connectors.map((item, index) => {
      return {
        label: item.name,
        value: item.id.toString(),
        render: () => {
          return (
            <BoxDetailConnector>
              <b>Descrição</b>
              <p>{item.description}</p>
              <b>Frequência de Atualização</b>
              <p>{TranslateFrequence(item)}</p>
              <Link style={{ cursor: "pointer" }} onClick={() => { setOpenDrawer(true); setItemSelectTable(item) }}>
                Alterar Frequencia
              </Link>
            </BoxDetailConnector>
          )
        }
      }
    });
  }

  return (
    <ContainerColumn>
      <Accordion items={createItens()} />
      <DrawerRight
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      >
        <BoxConnectionMemo />
        <ComboBoxInput
          label="Frequência"
          name="frequency"
          //onChange={handleChange}
          value={itemSelectTable?.default_type_repeat}
          items={allTimes}
        />
        <ComboBoxInput
          label="Executar ás"
          name="execute-hour"
          //onChange={handleChange}
          value={itemSelectTable?.default_hour.toString()}
          items={allHours}
        />
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#1090D2', width: '200px' }}
          type='submit'
          variant="contained"          
        >
          Salvar
        </Button>        
      </DrawerRight>
    </ContainerColumn>
  )
}