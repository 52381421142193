
import { HeaderDetail } from "../../components/HeaderDetail";
import { HeaderTitle } from "../../components/HeaderTitle";
import { TabBar } from "../../components/TabBar";
import { Container } from "./styles";

export const ExternalFonts: React.FC = () => {
  
  const items = () => {
    return [
      {
        label: 'Fontes Contratadas',
        render: (index:number) => <div key={index}>meus</div>
      },
      {
        label: 'Fontes da Comunidade',
        render: (index:number) => <div key={index}>Comunidade</div>
      }
    ]
  }

  return (
    <Container>
    <HeaderTitle>Fontes Externas</HeaderTitle>
    <HeaderDetail>Cruze os seus dados com dados de mercado, acesse bases públicas e privadas. Nós simplificamos o acesso as bases parceiras e você também têm a opção de publicar os seus dados e compartilhar de forma anonimizada, podendo ser até remunerado por isso.</HeaderDetail>
    <TabBar items={items()} floatButtons={[{label:"Quero publicar minha base"}]}></TabBar> 
    </Container>
  );
};
