"use client"

import { useState } from 'react';
import { Container, ContainerLogo, LogoMarca } from './styles';
import { FormValues, LoginProps } from './types';
import { BoxLogin } from '../../components/BoxLogin';
import { ForgotPassword } from '../../components/ForgotPassword';
import { RoutesFamilyEnum, useRouterFamilyNavigation } from '../../hooks/router-custom';

export const Login: React.FC<LoginProps> = () => {
  const navigate = useRouterFamilyNavigation();
  const [screenForgot, setScreenForgot] = useState(false);

  const [formValues, setFormValues] = useState<FormValues>({
    email: '',
    password: '',
    token: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const [errors] = useState<Partial<FormValues>>({});

  return (
    <Container>
      <ContainerLogo>
        <LogoMarca>
          <div style={{ height: '71.41px' }}>
            <img
              src='./conectabi-logo.png'
              alt="login sprite"
            />
          </div>

        </LogoMarca>
        <div className='container-login-image'>
          <img
            src="./login-sprite.png"
            alt="login sprite"
          />
        </div>

      </ContainerLogo>
      {screenForgot ?
        <ForgotPassword errors={errors} formValues={formValues} handleInput={handleChange} loggin={(e)=> setScreenForgot(false)} /> :
        <BoxLogin eventBox={(p: any) => setScreenForgot(true)} createAccount={e=>navigate(RoutesFamilyEnum.CreateAccount)} />}
    </Container>
  )
}
