import styled from "styled-components";

export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-top: 15px;
`

export const BoxDetailConnector = styled.div`
    padding: 15px;
`