
import React from 'react';
import { Container, ContainerInputs, ContainerTitles, MessageError, SelectBox, Title, TitleRight } from './styles';
import { ComboBoxInputProps } from './types';

const ComboBoxInput: React.FC<ComboBoxInputProps> = ({ disabled, error, value, name, label, onChange, items, onClickRight }) => {

  return (
    <Container>
      <ContainerTitles>
        <Title>{label}</Title>
        <TitleRight onClick={p => onClickRight ? onClickRight() : null}></TitleRight>
      </ContainerTitles>
      <ContainerInputs style={{borderColor:error?"#FF0000":"#D8D6DE"}}>
        <SelectBox disabled={disabled} defaultValue={value} name={name} onChange={onChange}>
          {items?.map((item, index)=>{
            return <option  key={index} value={item.value}>{item.label}</option>
          })}
        </SelectBox>    
      </ContainerInputs>
      {error ? <MessageError>{error}</MessageError> : null }
    </Container>
  );
};

export default ComboBoxInput;