
import { Title } from "./styles";
import { HeaderDetailProps } from "./types";

export const HeaderDetail: React.FC<HeaderDetailProps> = ({ children }) => {
  return (
    <Title>
      {children}
    </Title>
  );
};
