import { Button, TextField } from "@mui/material";
import { SubContainerLogin, ContainerLogin, SubTitle, Title } from "./styles";
import { BoxLoginProps } from "./types";
import TokenInput from "../TokenInput";
import { MessageBox, MessageTime, MessageTitle } from "../BoxLogin/styles";
//import { TokenInput } from "../TokenInput";

export const ForgotPassword: React.FC<BoxLoginProps> = ({ formValues, errors, handleInput, loggin }) => {
  return (
    <ContainerLogin>
      <SubContainerLogin>
        <Title className='h1-title'>Esqueceu a Senha ?</Title>
        <SubTitle>Não se preocupe vamos recupera-la com segurança.</SubTitle>

        <TextField
          fullWidth
          type="email"
          sx={{ mb: 2 }}
          name="email"
          variant="outlined"
          value={formValues.email}
          onChange={handleInput}
          label="Email"
          placeholder="exmple@mail.com"
          error={!!errors.email}
          helperText={(errors.email) as string}
        />

        <TokenInput length={5} onChange={(v: string) => console.log(v)} />

        <MessageBox>
          <MessageTitle>Não recebeu o código de segurança?</MessageTitle>
          <MessageTime>Voce poderá solicitar um novo código em 30 segundos</MessageTime>
        </MessageBox>
        <TextField
          fullWidth
          sx={{ mb: 2 }}
          name="password"
          type="password"
          label="Senha"
          autoComplete="on"
          variant="outlined"
          value={formValues.password}
          onChange={handleInput}
          placeholder="*********"
          error={!!errors.password}
          helperText={(errors.password) as string}
        />

        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#1090D2', width: '100%' }}
          type='submit'
          variant="contained"
          onClick={loggin}
        >
          Alterar Senha
        </Button>

        <Button
          sx={{ textTransform: 'none' }}
          style={{ width: '100%' }}
          variant="outlined"
          onClick={loggin}
        >
          Voltar
        </Button>
      </SubContainerLogin>
    </ContainerLogin>
  );
};
