import styled from "styled-components";
import arrowDown from "../../assets/svg/arrow-down.svg";

export const Container = styled.div`
    height: auto;
    width: 100%;
    border-radius : 17px;   
    padding: 0px 10px 2px 10px;  
`;

export const Item = styled.div`
    
`

export const TitleItem = styled.div`
    height: 46px;
    display: flex;
    align-items: center;    
    justify-content: space-between;
    border-bottom: 1px solid #EBE9F1;
`;

export const ContentItem = styled.div`

`;

export const BoxSelect = styled.div`
    width: 45px;
    height: 100%;    
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url(${arrowDown});    
    background-position: center center;
    background-size: 18px 16px;     
`
