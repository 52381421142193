
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { BoxConnection } from "../../components/BoxConnection";
import { GroupBoxConnection } from "../../components/GroupBoxConnection";
import { HeaderDetail } from "../../components/HeaderDetail";
import { HeaderTitle } from "../../components/HeaderTitle";
import { TabBar } from "../../components/TabBar";
import { useApi } from "../../hooks/ApiContext";
import { ReactComponent as LoadingAnime } from "../../assets/svg/animated/load.svg";
import { RoutesFamilyEnum, useRouterFamilyNavigation } from "../../hooks/router-custom";
import { BoxNothingConnectors, BoxSearch, BoxSearchInput, BoxTypesConnections, Container } from "./styles";
import { Connector, ConnectorFromCompany } from "./types";
import TextBoxInput from "../../components/TextBoxInput";
import { Tag } from "../../components/Tag";

export const Connects: React.FC = () => {
  const { getAllApplications, getAllApplicationsFromCompany } = useApi();
  const navigate = useRouterFamilyNavigation();
  const [allConnectors, setAllConnectors] = useState<Array<Connector>>([]);
  const [companyConnectors, setCompanyConnectors] = useState<Array<ConnectorFromCompany>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const initialized = useRef(false);

  const allTypesConnections = [
    { label: 'Banco de Dados', color: '#EA5455' },
    { label: 'E-commerce', color: '#7367F0' },
    { label: 'Produtividade', color: '#28C76F' },
    { label: 'Vendas e CRM', color: '#C77E28' },
    { label: 'Social Mídia', color: '#5B5B5B' },
    { label: 'Recursos Humanos', color: '#EA5455' },
    { label: 'Marketing', color: '#7367F0' },
    { label: 'Arquivos', color: '#28C76F' },
    { label: 'Tecnologia', color: '#C77E28' },
    { label: 'Financeiro', color: '#5B5B5B' }
  ];

  const fillCompanyConnectsAll = () => {
    const listConnectors = companyConnectors.map((item, index) => {
      let connector: Connector = { id: 0, name: '', brand: '', total_connectors: 0, color: 'green', description: '', category: '' };
      const details = allConnectors.filter(f => f.id === item.application_id);
      if (details.length > 0) {
        connector = details[0];
      }
      return <BoxConnection
        key={index}
        image={connector.brand ? connector.brand : ''}
        title={connector.name}
        detail={`${connector.total_connectors ? connector.total_connectors.toString() : '0'} integrações disponíveis`}
        tag={{ text: connector.category, color: String(connector.color) }}
        onClick={() => navigate(RoutesFamilyEnum.ManageConnection, connector.name + '/edit')}
      />
    });
    return listConnectors.length > 0 ? <GroupBoxConnection>{listConnectors}</GroupBoxConnection> : <BoxNothingConnectors>{loading ? <LoadingAnime /> : "Nenhum Conector Encontrado"}</BoxNothingConnectors>
  }

  const searchConnects = () => {
    return (<BoxSearch>
      <HeaderTitle md="true">Encontre o conector do seu sistema!</HeaderTitle>
      <HeaderTitle sm="true">Temos mais de 234 opções e nossa comunidade cresce todos os dias</HeaderTitle>
      <BoxSearchInput>
        <TextBoxInput
          type="text"
          label={""}
          name={"search"}
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeHolder="Pesquise aqui..."
        />
      </BoxSearchInput>
      <BoxTypesConnections>
        {allTypesConnections.map((item) => {
          return <Tag text={item.label} color={item.color} />
        })}
      </BoxTypesConnections>
    </BoxSearch>);
  }

  const fillConnectsAll = () => {
    const listConnectors = allConnectors.filter(f=>search!=="" ? f.name.toLowerCase().indexOf(search.toLowerCase())>-1 : true).map((item, index) => {
      return <BoxConnection
        key={index}
        image={item.brand ? item.brand : ''}
        title={item.name}
        detail={`${item.total_connectors ? item.total_connectors.toString() : '0'} integrações disponíveis`}
        tag={{ text: item.category, color: String(item.color) }}
        onClick={() => navigate(RoutesFamilyEnum.ManageConnection, item.name + '/new')}
      />
    });
    return <GroupBoxConnection>{searchConnects()}{listConnectors}</GroupBoxConnection>
  }

  const fetchData = useCallback(() => {
    setLoading(true);
    const processingApplication = getAllApplications();
    const processingCompany = getAllApplicationsFromCompany();

    Promise.all([processingApplication, processingCompany]).then(([responseApplication, responseCompany]) => {
      const all = responseApplication.original.data.data.map(item => {
        return item;
      });
      setAllConnectors(all);
      const allCompany = responseCompany.original.data?.map(item => {
        return item;
      });
      if (allCompany) {
        setCompanyConnectors(allCompany);
      }
      setLoading(false);
    });
  }, [getAllApplications, getAllApplicationsFromCompany]);

  useLayoutEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      fetchData();
    }
  }, [fetchData]);

  return (
    <Container>
      <HeaderTitle>Conectores</HeaderTitle>
      <HeaderDetail>Acesse os dados dos seus sistemas, ou bancos de dados, utilize uma das nossas centenas de conexões, ou crie seu próprio conector. Você também pode compartilhar esse conector com a comunidade e ganhar créditos na plataforma sempre que outra pessoa utilizar o seu conector!</HeaderDetail>
      <TabBar items={
        [
          {
            label: 'Meus Conectores',
            render: (index: number) => {
              return fillCompanyConnectsAll()
            }
          },
          {
            label: 'Conectores da Comunidade',
            render: (index: number) => fillConnectsAll()
          }
        ]
      } floatButtons={[{ label: "Criar um conector" }]}></TabBar>
    </Container>
  );
};

