import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 50px 50px 50px;
`;

export const BoxHeader = styled.div`
    display: flex;
    gap: 25px;
`

export const ExitTitle = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #EA5455;
    display: flex;
    align-items: center;    
    gap: 5px;
    cursor: pointer;
`