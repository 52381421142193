import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const BoxIcon = styled.div`
    width: 38px;
    height: 37px;
    background-color: #1090D2;
    box-shadow: 0px 3px 6px rgba(114, 102, 239, 0.4);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;    
`
export const BoxTitle = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
`

export const Title = styled.label`
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #1090D2;
`
export const SubTitle = styled.label`
    margin: 0px;
    padding: 0px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #B9B9C3;
`