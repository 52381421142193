import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 50px 50px 50px;
    width: 100%;
`;

export const ContainerHeaderArrow = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 0px 20px 0px;
`

export const ContainerIcon = styled.div`
    cursor:pointer;
    margin-right:15px;
`

export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-top: 15px;
`
export const ContainerDrawer = styled.div`
    padding: 30px 50px 50px 50px;
    width: 500px;
`

export const ContainerHeaderDrawer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`

export const AlignBoxTitleHeaderDrawer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
export const ContainerInsideAccordion = styled.div`
    padding: 30px 50px 50px 50px;
`