import { Container } from "./styles";
import { GroupBoxConnectionProps } from "./types";

export const GroupBoxConnection: React.FC<GroupBoxConnectionProps> = ({ children, ...attrs }) => {
  return (
    <Container {...attrs}>      
      {children}
    </Container> 
  );
};
