import styled from "styled-components";

export const Title = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #A5A5A5;
    padding-top: 15px;
`;