import styled from "styled-components";
import checked from "../../assets/svg/checked.svg";

export const Container = styled.div`
    height: 100%;
    align-items: center;
    display: flex;
    cursor: pointer;
`;


export const LabelAccordion = styled.label`
    width: calc(100% - 28px);
    cursor: pointer;
`

export const StyledCheckbox = styled.div`
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #7367F0;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-image: url(${checked});    
    background-position: center;
    background-size: 10px 10px; 
    margin-right: 10px;
    filter: drop-shadow(0px 2px 4px rgba(115, 103, 240, 0.4));
`;

export const StyledCheckboxInactive = styled.div`
    display: inline-block;
    width: 18px;
    height: 18px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 10px;
`;