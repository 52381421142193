import styled from "styled-components";

export const Container = styled.div`
    height: 37px;
    padding: 3px 20px 3px 20px;
    border-radius : 5px;   
    display: flex;
    justify-content: start;
    align-items: center;    
    border: solid 1px #7367F0;
    cursor: pointer;
`;

export const Title = styled.p`
    margin: 0px;
    padding: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #7367F0;
`;
