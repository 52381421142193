import { useCallback, useEffect, useState } from "react";
import ComboBoxInput from "../ComboBoxInput";
import TextBoxInput from "../TextBoxInput";
import { BoxInputs, BoxLineInputs, Container } from "./styles";
import { BoxCreateAccountCompanyFormValues, BoxCreateAccountCompanyProps } from "./types";
import { ItemCombo } from "../ComboBoxInput/types";
import { useApi } from "../../hooks/ApiContext";

export const BoxCreateAccountCompany: React.FC<BoxCreateAccountCompanyProps> = ({formValue, formErrors, onFormValues}) => {
    const { Category } = useApi();

    const [loading, setLoading] = useState<boolean>(false);
    const [allCategories, setAllCategories] = useState<Array<ItemCombo>>([
        { label: "Salão de Beleza", value: "Salão de Beleza" }, { label: "valor 2", value: "val-2" }, { label: "valor 3", value: "val-3" },
    ]);
    
    const [formValues, setFormValues] = useState<BoxCreateAccountCompanyFormValues>({
        company: formValue.company,
        document: formValue.document,
        category:formValue.category,
        url:formValue.url,
    });

    const setHandleValues = useCallback((name: string, value: string) => {
        const newForm = {
            ...formValues,
            [name]: value,
        };
        setFormValues(newForm);
        onFormValues(newForm);
    },[formValues,onFormValues]);

    // function formatarString(str: string, pattern: string): string {
    //     let index = 0;
    //     return pattern.replace(/#/g, () => str[index++] || '');
    //   }
      
      
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setHandleValues(name, value);
    };

    // const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    //     const { name, value } = event.target;
    //     const formated = formatarString(value, '###.###.###-##');
    //     console.log(formated);
    //     setHandleValues(name, formated);
    // };    

    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await Category();
        console.log(response);
        const all = response.data.map(item=>{
            return {label:item.category,value:item.category};
        });
        setAllCategories(all);
        setHandleValues("category", all[0].value);
        setLoading(false);
    }, [Category,setHandleValues]); 
  
    useEffect(() => {
        fetchData();    
    // eslint-disable-next-line        
    }, []); 

    return (
        <Container>
            <BoxInputs>
                <BoxLineInputs>
                    <TextBoxInput
                        label="Nome"
                        type="text"
                        disabled={loading}
                        subLabel=""                        
                        name="company"
                        placeHolder="John Wick"
                        value={formValues["company"]}
                        onChange={handleChange}
                        error={formErrors["company"]}
                    />
                    <TextBoxInput
                        label="CPF/CNPJ"
                        type="text"
                        subLabel=""
                        disabled={loading}
                        name="document"
                        placeHolder="000.000.000-00"
                        onChange={handleChange}
                        value={formValues["document"]}
                        error={formErrors["document"]}
                    />
                </BoxLineInputs>
                <BoxLineInputs>
                    <ComboBoxInput
                        label="Categoria"
                        name="category"
                        onChange={handleChange}
                        value={formValues["category"]}
                        items={allCategories}
                        disabled={loading}
                        error={formErrors["category"]}
                    />
                    <TextBoxInput
                        label="Personalize Sua URL"
                        type="text"
                        subLabel=""
                        disabled={loading}
                        placeHolder="conecta.bi/sua-empresa"
                        onChange={handleChange}
                        name="url"
                        value={formValues["url"]}
                        error={formErrors["url"]}
                    />
                </BoxLineInputs>
            </BoxInputs>
        </Container>
    );
};
