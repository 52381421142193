import { Container, LogoTipo } from "./styles";

export interface SideMenuProps {
  children?: React.ReactNode;
}

export const SideMenu: React.FC<SideMenuProps> = ({ children, ...attrs }) => {
  return (
    <Container {...attrs}>
      <LogoTipo>
        <img
          src='./conectabi-logo.png'
          alt="login sprite"
        />
      </LogoTipo>
      {children}
    </Container>
  );
};
