import { Tag } from "../Tag";
import { BoxImageTitle, BoxTitleTag, Container, ContainerInactive, Detail, Title } from "./styles";
import { BoxConnectionProps } from "./types";

export const BoxConnection: React.FC<BoxConnectionProps> = ({ inactive, image, title, detail, tag, onClick }) => {
  return (
    inactive ?
    <ContainerInactive style={{height:detail ? "107px" : "80px"}} >
      <BoxImageTitle>
        <img height={32} width={32} src={image} alt={"nothing"} />
        <BoxTitleTag>
          <Title>{title}</Title>
          <Tag text={tag.text} color={tag.color.toString()} />
        </BoxTitleTag>
      </BoxImageTitle>
      {detail ? <Detail>{detail}</Detail> : null}
    </ContainerInactive> :
    <Container style={{height:detail ? "107px" : "80px"}} onClick={onClick} >
      <BoxImageTitle>
        <img height={32} width={32} src={image} alt={"nothing"} />
        <BoxTitleTag>
          <Title>{title}</Title>
          <Tag text={tag.text} color={tag.color.toString()} />
        </BoxTitleTag>
      </BoxImageTitle>
      {detail ? <Detail>{detail}</Detail> : null}
    </Container>

  );
};
