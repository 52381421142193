import styled from "styled-components"

export const ContainerSimple = styled.div`
  min-height:16px;
  min-width:16px;    
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  min-height:16px;
  min-width:16px;    
  padding-right: 25px;
  padding-left: 25px;  
`
