import { Menu } from "../Menu";
import { SideMenu } from "../SideMenu";
import { Container, ContainerWithMenu } from "./styles";

export interface BodyProps {
  children?: React.ReactNode;
  menu:boolean;
}

export const Body: React.FC<BodyProps> = ({ children, menu, ...attrs }) => {
    return (      
      menu ? <ContainerWithMenu {...attrs}><SideMenu> <Menu /> </SideMenu>  {children} </ContainerWithMenu> : 
      <Container {...attrs}>{children} </Container> 
    );
};
