import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: solid 1px #D8D6DE;
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;    
    align-items: center;
    margin-bottom: 50px;
`;

