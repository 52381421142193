
import React from 'react';
import { Container, ContainerInputs, ContainerTitles, InputBox, MessageError, Title, TitleRight } from './styles';
import { TextBoxInputProps } from './types';

const TextBoxInput: React.FC<TextBoxInputProps> = ({ ref, disabled, name, label, onChange, value, subLabel, type, placeHolder, onClickRight, error }) => {

  return (
    <Container>
      <ContainerTitles>
        <Title>{label}</Title>
        <TitleRight onClick={p => onClickRight ? onClickRight() : null}>{subLabel}</TitleRight>
      </ContainerTitles>
      <ContainerInputs>
        <InputBox 
          disabled={disabled}
          ref={ref}
          name={name} 
          placeholder={placeHolder} 
          type={type} 
          onChange={onChange} 
          value={value} 
          style={{borderColor:error?"#FF0000":"#D8D6DE"}}
        />
      </ContainerInputs>
      {error ? <MessageError>{error}</MessageError> : null }
    </Container>
  );
};

export default TextBoxInput;