import styled from "styled-components";
import arrowDown from "../../assets/svg/arrow-down.svg";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
`

export const ContainerTitles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ContainerInputs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #D8D6DE;    
`

export const SelectBox = styled.select`
    appearance: none;
    width: 100%;
    height: auto;
    font-size: 12px;
    border: none; 
    border-radius: 5px;    
    color: #666666;
    padding: 10px 15px 10px 15px;    
    overflow: hidden;
    background-repeat: no-repeat;
    background-image: url(${arrowDown});    
    background-position: right 0.75rem center;
    background-size: 18px 16px;    
`

export const ArrowIcon = styled.span`
  position: relative;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  text-indent: 1px;
  text-overflow: '';
`;


export const Title = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6E6B7B;
    margin: 0px 0px 3px 0px;    
`

export const TitleRight = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1090D2;
    margin: 0px 0px 3px 0px;    
    cursor: pointer;
`
export const MessageError = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FF0000;
    margin: 0px 0px 3px 0px;    
`