
import React, { useState, useRef } from 'react';
import { TokenInputProps } from './types';
import { Container, ContainerInputs, InputBox, Title } from './styles';

const TokenInput: React.FC<TokenInputProps> = ({ length, onChange }) => {
  const [otp, setOTP] = useState<string[]>(new Array(length).fill(''));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (!isNaN(Number(value))) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      onChange(newOTP);
      if (index < length - 1 && value !== '') {
        inputsRef.current[index + 1]?.focus();
      }      
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain').slice(0, length);
    const newOTP = [...otp];
    for (let i = 0; i < pasteData.length; i++) {
      newOTP[i] = pasteData[i];
    }
    setOTP(newOTP);
    onChange(newOTP);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <Container>
      <Title>Informe o código enviado para o seu e-mail</Title>      
      <ContainerInputs>
      {otp.map((digit, index) => (
        <InputBox
          key={index}
          type="tel"
          inputMode="numeric"
          maxLength={1}
          value={digit}
          onChange={(e) => handleInput(e, index)}
          onPaste={handlePaste}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(input) => {
            inputsRef.current[index] = input;
          }}
        />
      ))}
      </ContainerInputs>
    </Container>
  );
};

export default TokenInput;