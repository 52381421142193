import { Container, Title } from "./styles";
import { ButtonFreeLightProps } from "./types";

export const ButtonFreeLight: React.FC<ButtonFreeLightProps> = ({ click, children, ...attrs }) => {
  return (
    <Container onClick={e=>click ? click() : null} {...attrs}>      
      <Title>{children}</Title>
    </Container>
  );
};
