import { MouseEventHandler } from "react";

export enum Color{
    red = "red", 
    green = "green", 
    purple = "purple",
    orange = "orange",
    blue = "blue"
}

export interface BoxConnectionProps {
    image:string;
    title:string;
    detail?:string;
    tag:{text:string,color:string};
    inactive?: boolean;
    onClick?: MouseEventHandler | undefined;
}