/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from "axios";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { AllApplicationResponse, ApplicationByEmailResponse, CategoryResponse, CompanyCreationResponse, ConnectorFromApplicationResponse, ConnectorItem, DashBoardResponse, DetailApplicationFromCompany, DetailConnectorResponse, ExistSlugResponse, LoginResponse, UserLoginResponse } from "../api/responses/types";
import { isBefore } from "date-fns";
import http from "../utils/axios";
import { CreateCompanyRequest } from "../api/requests/types";

// ============================================================
export type AuthOptions = {
  getDashBoard():Promise<DashBoardResponse>;
  GetApplicationsByEmail(email: string):Promise<ApplicationByEmailResponse>;
  getAllApplications():Promise<AllApplicationResponse>;
  getAllApplicationsFromCompany():Promise<ConnectorFromApplicationResponse>;
  getDetailApplication(applicationId:string):Promise<DetailApplicationFromCompany>;
  CreateCompany(company: CreateCompanyRequest): Promise<CompanyCreationResponse>;
  getDetailConnector(applicationId:string): Promise<DetailConnectorResponse> ;
  Category(): Promise<CategoryResponse>;
  ExistSlug(slug: string): Promise<ExistSlugResponse>;  
  signin(username: string, password: string): Promise<LoginResponse>;
  signout(): void;
  getUser(): UserLoginResponse;
  getIsLogged(): boolean;
  getApplicationStorage(): ConnectorItem[];
  loggedUser: { name: string } | null;
  isLoading: boolean;
  isError: boolean;
  isAuthenticated: boolean;
  errorMessage: string | null;
  slug: string;
};

export interface SigninToken {
  accessToken: string;
  accessTokenExpiration: string;
}
// ============================================================

export const ApiContext = createContext({} as AuthOptions);

// ============================================================
type authProviderProps = { children?: ReactNode };
// ============================================================

const API_ACCESS_TOKEN_STORAGE_KEY = "@my_access_token";
const API_ACCESS_TOKEN_EXP_STORAGE_KEY = "@my_access_token_exp";
const API_ACCESS_SLUG_STORAGE_KEY = "@my_access_slug";
const API_ACCESS_USER_STORAGE_KEY = "@my_access_user";
const API_ACCESS_CONNECTS_STORAGE_KEY = "@my_connects";

const ApiProvider = ({ children }: authProviderProps) => {
  const [isStarting, setIsStarting] = React.useState<boolean>(true);  
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [slug, setSlug] = React.useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [loggedUser, setLoggedUser] = React.useState<{ name: string } | null>(null);

  const saveConnectsAll = (connectors: ConnectorItem[]) => {
    sessionStorage.setItem(API_ACCESS_CONNECTS_STORAGE_KEY, JSON.stringify(connectors));
  };

  const saveAccessUser = (user: UserLoginResponse) => {
    localStorage.setItem(API_ACCESS_USER_STORAGE_KEY, JSON.stringify(user));
  };

  const saveAccessSlug = (slug: string) => {
    localStorage.setItem(API_ACCESS_SLUG_STORAGE_KEY, slug);
  };

  const saveAccessToken = (token: string, exp: string) => {
    localStorage.setItem(API_ACCESS_TOKEN_STORAGE_KEY, token);
    localStorage.setItem(API_ACCESS_TOKEN_EXP_STORAGE_KEY, exp);
  };

  const deleteAccessToken = () => {
    localStorage.removeItem(API_ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(API_ACCESS_TOKEN_EXP_STORAGE_KEY);
  };

  const getUser = (): UserLoginResponse => {
    const userStorage = localStorage.getItem(API_ACCESS_USER_STORAGE_KEY);
    return userStorage ? JSON.parse(userStorage) : undefined;
  };

  const getSigninToken = (): SigninToken => {
    const accessToken = localStorage.getItem(API_ACCESS_TOKEN_STORAGE_KEY);
    const accessTokenExpiration = localStorage.getItem(API_ACCESS_TOKEN_EXP_STORAGE_KEY);
    return accessToken && accessTokenExpiration ? ({accessToken,accessTokenExpiration}) : ({accessToken:"",accessTokenExpiration:""})
  };

  const getSlug = (): string => {
    const slugStorage = localStorage.getItem(API_ACCESS_SLUG_STORAGE_KEY);
    return slugStorage ? slugStorage : "";
  };

  const getApplicationStorage = (): ConnectorItem[] => {
    const applicationsAllStorage = sessionStorage.getItem(API_ACCESS_CONNECTS_STORAGE_KEY);
    return applicationsAllStorage ? JSON.parse(applicationsAllStorage) : [];
  };

  const getIsLogged = () => {
    return getSigninToken().accessToken !== "";
  }

  const getDashBoard = async () : Promise<DashBoardResponse> => {
    try{
      const response = await http.get<DashBoardResponse>(`/dashboard`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      signout();
      window.location.hash = ("/"+getSlug()+"/login");
      window.location.reload();
      return err.response?.data as DashBoardResponse;
    } 
  };

  const getDetailConnector = async (applicationId:string) : Promise<DetailConnectorResponse> => {
    try{
      const response = await http.get<DetailConnectorResponse>(`/applications/${applicationId}/connectors`);      
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      window.location.hash = ("/"+getSlug()+"/login");
      window.location.reload();
      return err.response?.data as DetailConnectorResponse;
    } 
  };  

  const getDetailApplication = async (applicationId:string) : Promise<DetailApplicationFromCompany> => {
    try{
      const user = getUser();
      const response = await http.get<DetailApplicationFromCompany>(`/company/${user.company_id}/application/${applicationId}`);      
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      window.location.hash = ("/"+getSlug()+"/login");
      window.location.reload();
      return err.response?.data as DetailApplicationFromCompany;
    } 
  };  

  const getAllApplications = async () : Promise<AllApplicationResponse> => {
    try{
      const response = await http.get<AllApplicationResponse>(`/applications`);
      saveConnectsAll(response.data.original.data.data);
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      window.location.hash = ("/"+getSlug()+"/login");
      window.location.reload();
      return err.response?.data as AllApplicationResponse;
    } 
  };  

  const getAllApplicationsFromCompany = async () : Promise<ConnectorFromApplicationResponse> => {
    try{
      const user = getUser();
      const response = await http.get<ConnectorFromApplicationResponse>(`/applications/company/${user.company_id}`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      window.location.hash = ("/"+getSlug()+"/login");
      window.location.reload();
      return err.response?.data as ConnectorFromApplicationResponse;
    } 
  };    

  const GetApplicationsByEmail = async (email: string): Promise<ApplicationByEmailResponse> => {
    try{
      const response = await http.get<ApplicationByEmailResponse>(`/public/slug/email?slug=${email}`);
      const responseSlug = response.data.original.data.slug;
      saveAccessSlug(responseSlug);
      setSlug(responseSlug);
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      return err.response?.data as ApplicationByEmailResponse;
    } 
  };

  const CreateCompany = async (company: CreateCompanyRequest): Promise<CompanyCreationResponse> => {
    try {
      const response = await http.post<CompanyCreationResponse>(`/company/cadastro`, company);
      return response.data;
    } catch (error) {
      const err = error as AxiosError
      console.log(err.response?.data)
      return err.response?.data as CompanyCreationResponse;
    }
  };
  
  const Category = async (): Promise<CategoryResponse> => {
    const response = await http.get<CategoryResponse>(`/categories/get-active`);
    return response.data;
  };
  
  const ExistSlug = async (slug: string): Promise<ExistSlugResponse> => {
    const response = await http.get<ExistSlugResponse>(`/public/slug?slug=${slug}`);
    return response.data;
  };
  

  const setError = (reason: string | null) => {
    setIsError(!!reason);
    setErrorMessage(reason);
  };

  const signin = async (username: string, password: string): Promise<LoginResponse> => {
    try {
      let loggedUserLocal = null,
        errorMessage = null;
      
      setIsLoading(true);      
      const response = await http.post<LoginResponse>(`/auth/login`, {email:username,password:password});
      if (response.data){
        saveAccessToken(response.data.data.access_token, response.data.data.expires_in.toString());
        saveAccessUser(response.data.data.user)
      // const response = await postSignin(username, password); 
      // // saving access token before requesting logged user
      // if (response){
      //   saveAccessToken(
      //     response.data.accessToken,
      //     response.data.accessTokenExpiration
      //   );

        http.defaults.headers.common.Authorization = `Bearer ${getSigninToken().accessToken}`;
      //   //const responseUser = await getLoggedUser();
      //   //loggedUserLocal = { name: responseUser.data.nome};
        setError(errorMessage);
        setLoggedUser(loggedUserLocal);
        setIsAuthenticated(true);
         return response.data;
      }else{
        return response.data;   
      }
    } catch (error) {
      const err = error as AxiosError
      return err.response?.data as LoginResponse;
    }
  };

  const signout = () => {
    deleteAccessToken();
    setIsAuthenticated(false);
    setLoggedUser(null);
  };

  const hasSigninToken = () => {
    const signinToken = getSigninToken();

    return !!signinToken.accessToken && !!signinToken.accessTokenExpiration;
  };

  const initAuth = async () => {
    setIsStarting(true);
    const s = getSlug(); 
    setSlug(s);
    if (hasSigninToken()) {
      const exp = new Date(getSigninToken().accessTokenExpiration);

      if (isBefore(exp, Date.now())) {
        setIsAuthenticated(false);
        return;
      }

      let loggedUserLocal = null,
        isAuthenticated = false;

      setIsLoading(true);

      try {

        //const response = await getLoggedUser();
        isAuthenticated = true;
        //loggedUserLocal = { name: response.data.nome};
      } finally {
        setLoggedUser(loggedUserLocal);
        setIsAuthenticated(isAuthenticated);
        setIsLoading(false);
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsStarting(false);
  };

  useEffect(() => {
    initAuth();

    return () => {

    };
  }, []);

  useEffect(() => {
    if (getIsLogged()) {
      http.defaults.headers.common.Authorization = `Bearer ${
        getSigninToken().accessToken
      }`;
    } else {
      http.defaults.headers.common.Authorization = undefined;
    }
  }, [isAuthenticated]);

  return (
    <ApiContext.Provider
      value={{
        getDashBoard,
        GetApplicationsByEmail,
        getAllApplications,
        getAllApplicationsFromCompany,
        getDetailApplication,
        getDetailConnector,
        CreateCompany,        
        Category,
        ExistSlug,
        getUser,
        signin,
        signout,
        getIsLogged,
        getApplicationStorage,
        loggedUser,
        isLoading,
        isError,
        isAuthenticated,
        errorMessage,
        slug,
      }}
    >
      {isStarting === false ? children : null}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);

export default ApiProvider;
