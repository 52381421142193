/* eslint-disable no-fallthrough */
import React from "react";

import { Container, ContainerSimple } from "./styles";

import { ReactComponent as Disconnect } from "../assets/svg/disconnect.svg";
import { ReactComponent as Global } from "../assets/svg/global.svg";
import { ReactComponent as Mind } from "../assets/svg/mind.svg";
import { ReactComponent as Diagram } from "../assets/svg/diagram.svg";
import { ReactComponent as Config } from "../assets/svg/config.svg";
import { ReactComponent as Chart } from "../assets/svg/chart.svg";
import { ReactComponent as House } from "../assets/svg/house.svg";
import { ReactComponent as ArrowDown } from "../assets/svg/arrow-down.svg";
import { ReactComponent as ArrowBack } from "../assets/svg/arrow-back.svg";
import { ReactComponent as CloseX } from "../assets/svg/close-x.svg";
import { ReactComponent as CircleFamily } from "../assets/svg/circle-family.svg";
import { ReactComponent as RightArrowStep } from "../assets/svg/right-arrow-step.svg";
import { ReactComponent as LeftArrowStep } from "../assets/svg/left-arrow-step.svg";
import { ReactComponent as UserIcon } from "../assets/svg/user-icon.svg";
import { ReactComponent as CreditCard } from "../assets/svg/credit-card.svg";
import { ReactComponent as LogOut } from "../assets/svg/log-out.svg";
import { IconProps } from "./types";

export const Icon: React.FC<IconProps> = ({
  color,
  name,
  paddingDefault,
  ...attrs
}) => {
  let icon = <></>;
  switch (name) {
    case "mind":
      icon = <Mind stroke={color} {...attrs} />;
      break;
    case "chart":
      icon = <Chart fill={color} stroke={color} {...attrs} />;
      break;
    case "config":
      icon = <Config fill={color} stroke={color} {...attrs} />;
      break;
    case "circle-family":
      icon = <CircleFamily stroke={color} {...attrs} />;
      break;
    case "diagram":
      icon = <Diagram fill={color} stroke={color} {...attrs} />;
      break;
    case "arrow-down":
      icon = <ArrowDown fill={color} stroke={color} {...attrs} />;
      break;
    case "close-x":
      icon = <CloseX fill={color} stroke={color} {...attrs} />;
      break;      
    case "arrow-back":
      icon = <ArrowBack fill={color} stroke={color} {...attrs} />;
      break;
    case "house":
      icon = <House fill={color} stroke={color} {...attrs} />;
      break;
    case "global":
      icon = <Global fill={color} stroke={color} {...attrs} />;
      break;   
    case "user-icon":
      icon = <UserIcon stroke={color} {...attrs} />;
      break;  
    case "credit-card":
      icon = <CreditCard stroke={color} {...attrs} />;
      break;                      
    case "right-arrow-step":
      icon = <RightArrowStep fill={color} stroke={color} {...attrs} />;
      break;
    case "left-arrow-step":
      icon = <LeftArrowStep fill={color} stroke={color} {...attrs} />;
      break;    
    case "logout":
      icon = <LogOut  {...attrs} />;
      break;        
    case "disconnect":
      icon = <Disconnect fill={color} stroke={color} {...attrs} />;
      break;
    default:
      icon = <Disconnect fill={color} stroke={color} {...attrs} />;
  };
  return paddingDefault ? <Container >{icon}</Container> : <ContainerSimple >{icon}</ContainerSimple>;
};
