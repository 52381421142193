import { Button } from "@mui/material";
import { ImageCompany } from "../ImageCompany";
import { Container, ContainerButton, ContainerImage, Title } from "./styles";

export const UploadCompany: React.FC = ({ ...attrs }) => {
  return (
    <Container {...attrs}>
      <ContainerImage>
        <ImageCompany />
      </ContainerImage>
      <ContainerButton>
        <Title>{"Logo da Empresa"}</Title>
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ width: '100%', marginTop:"5px", marginLeft: "30px", marginRight: "30px" }}
          type='submit'
          variant="outlined"
        >
          Upload
        </Button>
      </ContainerButton>
    </Container>
  );
};
