import { BoxImageTitle, BoxTitleTag, Container, Detail, Title } from "./styles";
import { BoxConnectorDrawerProps } from "./types";

export const BoxConnectorDrawer: React.FC<BoxConnectorDrawerProps> = ({ image, title, detail }) => {
  return (
    <Container>
      <BoxImageTitle>
        <img height={42} width={42} src={image} alt={"nothing"} />
        <BoxTitleTag>
          <Title>{title}</Title>
          {detail ? <Detail>{detail}</Detail> : null}          
        </BoxTitleTag>
      </BoxImageTitle>
    </Container>

  );
};
