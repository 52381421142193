import { Container, Title } from "./styles";
import { BoxMessageProps } from "./types";

export const BoxMessage: React.FC<BoxMessageProps> = ({ text, color, active, ...attrs }) => {
  const colors = [
    {name:"green",backColor:"rgba(40, 199, 111, 0.12)", textColor:"#28C76F"},
    {name:"purple",backColor:"rgba(115, 103, 240, 0.12)", textColor:"#7367F0"},
    {name:"orange",backColor:"#FFE7CB", textColor:"#C77E28"},
    {name:"red",backColor:"rgba(234, 84, 85, 0.12)", textColor:"#EA5455"},
    {name:"blue",backColor:"rgba(0, 207, 232, 0.12)", textColor:"#00a8e8"},
  ];

  const getColor = (name:string) => {
    const selected = colors.filter(v=>v.name===name);
    if (selected.length>0)
      return selected[0] 
    else
      return colors[0] 
  }

  return ( active ?
    <Container style={{backgroundColor:getColor(color).backColor}} {...attrs}>
      <Title style={{color:getColor(color).textColor}}>{text}</Title>
    </Container> : <></>
  );
};
