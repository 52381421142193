import { Icon } from "../../Icons";
import { StepItem } from "../StepItem";
import { Container } from "./styles";
import { StepItemArray, StepsProps } from "./types";

export const Steps: React.FC<StepsProps> = ({ items }) => {

    const getItem = (item:StepItemArray, index:number, isLast:boolean) => {
        let result = [];
        result.push(<StepItem icon={item.icon} active={item.active} key={index} title={item.title} subTitle={item.subTitle} />);
        if (isLast === false){
            result.push(<Icon key={index+100} name={"right-arrow-step"} width={14} height={14} color={"#6E6B7B"} />);
        }
        return result;
    }

    return (
        <Container>
            {items.map((item,idx) => getItem(item,idx,idx===(items.length-1)))}
        </Container>
    );
};
