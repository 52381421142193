import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
`

export const ContainerTitles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ContainerInputs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const InputBox = styled.input`
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #D8D6DE;
    font-size: 12px;
    color: #666666;
    padding: 10px 15px 10px 15px;    
    &::placeholder {
        color: #B9B9C3;
    }
`
export const Title = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6E6B7B;
    margin: 0px 0px 3px 0px;    
`

export const TitleRight = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1090D2;
    margin: 0px 0px 3px 0px;    
    cursor: pointer;
`

export const MessageError = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FF0000;
    margin: 0px 0px 3px 0px;    
`