import { useState } from "react";
import { Container, ItemContainer, TabButtonItem, TabButtons, TabButtonsContainer, TabItemRight } from "./styles";
import { TabBarProps } from "./types";
import { ButtonFreeLight } from "../ButtonFreeLight";

export const TabBar: React.FC<TabBarProps> = ({ items, floatButtons }) => {
  const [active, setActive] = useState(0);
  return (
    <Container>
      <TabButtonsContainer>
        <TabButtons>
        {items.map((item, idx)=>{
          return <TabButtonItem 
                    key={idx} 
                    onClick={e=>setActive(idx)}                     
                    style={{borderBottom:idx===active ? "solid 2px #4945FF" : "solid 2px white",
                    color:idx===active ? "#4945FF" : "#666687" }}
                  >{item.label}</TabButtonItem>
        })}
        </TabButtons>
        <TabItemRight>
          {floatButtons?.map((item, idx)=> {
            return <ButtonFreeLight key={idx}>{item.label}</ButtonFreeLight>
          })}          
        </TabItemRight>
      </TabButtonsContainer>
      {items.map((item, idx)=>{
          return idx===active ? <ItemContainer key={idx}> {item.render(idx)} </ItemContainer> : null;
        })}
    </Container>
  );
};
