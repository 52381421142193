import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 50px 50px 50px;
`;

export const BoxNothingConnectors = styled.div`
    height: 100px;    
    display: flex;
    justify-content: center;
    align-items: center;
`
export const BoxSearch = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 35px;
`

export const BoxSearchInput = styled.div`
    width: 600px;
    margin-top: 20px;
`
export const BoxTypesConnections = styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 500px;
`