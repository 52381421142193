import { Body } from "../../components/Body";

interface PropsBody {
  children?: React.ReactNode;
  menu:boolean;
}

export const Layout: React.FC<PropsBody> = ({children, menu}) => {
  return (
    <Body menu={menu}>
      {children}
    </Body>
  );
};

export default Layout;
