import { Icon } from "../../Icons";
import { BoxIcon, BoxTitle, Container, SubTitle, Title } from "./styles";
import { StepItemProps } from "./types";

export const StepItem: React.FC<StepItemProps> = ({ icon, active, title, subTitle }) => {
  return (
    <Container>
      <BoxIcon style={{backgroundColor:active ? "#1090D2":"rgba(186, 191, 199, 0.12)", boxShadow:active ? "0px 3px 6px rgba(114, 102, 239, 0.4)" : "0px 0px 0px rgba(114, 102, 239, 0.4)"}}>
        <Icon name={icon} width={18} height={18} color={active ? "white" : "#6E6B7B"} />
      </BoxIcon>
      <BoxTitle>
        <Title style={{color:active ? "#1090D2" : "#6E6B7B"}} >{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </BoxTitle>      
    </Container>
  );
};
