import styled from "styled-components";

export const Container = styled.div`
    height: auto;
    width: auto;
    border-radius : 17px;   
    padding: 0px 10px 2px 10px;
    width: max-content;      
`;

export const Title = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
`
