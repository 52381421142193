
import { HeaderDetail } from "../../components/HeaderDetail";
import { HeaderTitle } from "../../components/HeaderTitle";
import { TabBar } from "../../components/TabBar";
import { Container } from "./styles";

export const Segmentation: React.FC = () => {

  const items = () => {
    return [
      {
        label: 'Segmentações',
        render: (index:number) => <div key={index}>meus</div>
      },
      {
        label: 'Campanhas',
        render: (index:number) => <div key={index}>comunidade</div>
      }
    ]
  }

  return (
    <Container>
    <HeaderTitle>Segmentação</HeaderTitle>
    <HeaderDetail>Aqui você consegue criar recortes da sua base de cliente, por exemplo CLIENTES INATIVOS, ANIVERSARIANTES, MELHORES CLIENTES e acompanhar esses grupos seja via relatórios e indicadores, ou na automatização de campanhas para clientes em determinada segmentação.</HeaderDetail>
    <TabBar items={items()} floatButtons={[{label:"Criar Segmentação"}]}></TabBar> 
    </Container>
  );
};
