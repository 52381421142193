import styled from "styled-components";

export const Container = styled.div`
    height: auto;
    border-radius: 7px;
    width: 100%;  
    margin-top:15px;
`;

export const Title = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0px;
    padding: 10px;
`
