import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    overflow: auto;    
`;

export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
`

export const ContainerColumnBoxes = styled.div`
    display: flex;
    flex-direction: column;
    width: 700px;
`

export const SubHeaderBoxes = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
`;

export const DetailsBoxes = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
`