import styled from "styled-components";

export const ContainerActive = styled.div`
    background: rgba(95, 207, 227, 0.25);
    height: 64px;
    margin: 10px;
    border-radius : 12px;   
    display: flex;
    justify-content: start;
    align-items: center;    
    cursor: pointer;

    &:hover {
        background: rgba(95, 207, 227, 0.25); 
        > p {
            color: #1090D2;
        }
        > div {
            > svg {                
                fill:#1090D2;
                stroke:#1090D2;
            }
        }
    }
`;

export const ContainerInactve = styled.div`
    background: white;
    height: 64px;
    margin: 10px;
    border-radius : 12px;   
    display: flex;
    justify-content: start;
    align-items: center;    
    cursor: pointer;

    &:hover {
        background: rgba(95, 207, 227, 0.15); 
        > p {
            color: rgba(16, 144, 210, 0.65);
        }
        > div {
            > svg {                
                fill:none;
                stroke:rgba(16, 144, 210, 0.45);
            }
        }
    }
`;

export const TitleActive = styled.p`
    margin: 0px;
    padding: 0px;
    color:#1090D2;
`;

export const TitleInactive = styled.p`
    margin: 0px;
    padding: 0px;
    color:#A5A5A5;
`;