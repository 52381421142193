import { Container, ContainerColumn, ContainerColumnBoxes, DetailsBoxes, SubHeaderBoxes } from "./styles"
import { BoxManagerConfigurationProps } from "./types"
import TextBoxInput from "../TextBoxInput"
import { memo } from "react"
import { Button } from "@mui/material"
import { HeaderTitle } from "../HeaderTitle"

export const BoxManagerConfiguration: React.FC<BoxManagerConfigurationProps> = ({ vars }) => {

  const createItens = () => {
    return (<>{vars?.map((item, index) => {
      return (
        <TextBoxInput
          type="text"
          label={item.var_name}
          name={item.var_name.replaceAll(' ', '')}
          value={item.var_value}
          //onChange={e => setPassword(e.target.value)}
          placeHolder="Digite aqui..."
        />
      )
    })}</>);
  }

  const Allitens = memo(createItens);

  return (
    <Container>
      <ContainerColumn>
        <Allitens />
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#1090D2', width: '200px' }}
          type='submit'
          variant="contained"
        >
          Salvar
        </Button>
      </ContainerColumn>
      <HeaderTitle pad={"bottom"}>Inativar/Deletar aplicação e dados</HeaderTitle>
      <ContainerColumnBoxes>
        <SubHeaderBoxes >INATIVAR</SubHeaderBoxes>
        <DetailsBoxes>Utilize essa opção se você deseja pausar a integração atual. Você não perde as configurações já realizadas e em qualquer momento no futuro pode decidir reativar a integração. No período que um conector está inativo ele não realiza mais coletas de dados, mas mantem os dados já coletados até aqui.</DetailsBoxes>
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#EA5455', width: '200px' }}
          type='submit'
          variant="contained"
        >
          Inativar Aplicação
        </Button>
      </ContainerColumnBoxes>
      <ContainerColumnBoxes>
        <SubHeaderBoxes >DELETAR APLICAÇÃO</SubHeaderBoxes>
        <DetailsBoxes>Utilize essa opção APENAS se você têm certeza que não precisa mais dessa integração. Você perde todas as configurações já realizadas e deletamos todos os dados e tabelas já armazenados.</DetailsBoxes>
        <Button
          sx={{ my: 2, textTransform: 'none' }}
          style={{ background: '#EA5455', width: '200px' }}
          type='submit'
          variant="contained"
        >
          Deletar Aplicação
        </Button>
      </ContainerColumnBoxes>
    </Container>
  )
}