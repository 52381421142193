import styled from "styled-components";

export const Container = styled.div`
    padding: 30px 50px 50px 50px;
    width: 90%;
`;

export const ContainerHeaderArrow = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 0px 20px 0px;
`

export const ContainerIcon = styled.div`
    cursor:pointer;
    margin-right:15px;
`

export const ContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`

export const BoxLoading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
`