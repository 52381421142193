import { Drawer } from "@mui/material";
import { DrawerRightProps } from "./types";
import { AlignBoxTitleHeaderDrawer, ContainerDrawer, ContainerHeaderDrawer, ContainerIcon } from "./styles";
import { HeaderTitle } from "../../components/HeaderTitle";
import { Icon } from "../../Icons";

export const DrawerRight: React.FC<DrawerRightProps> = ({ children, openDrawer, setOpenDrawer, ...attrs }) => {
    return (
        <Drawer
            anchor={"right"}
            open={openDrawer}
        >
            <ContainerDrawer>
                <ContainerHeaderDrawer>
                    <ContainerIcon onClick={() => setOpenDrawer(false)}>
                        <Icon color="black" name="close-x" />
                    </ContainerIcon>
                    <AlignBoxTitleHeaderDrawer>
                        <HeaderTitle md={"true"}>Frequência de coleta de dados</HeaderTitle>
                    </AlignBoxTitleHeaderDrawer>
                </ContainerHeaderDrawer>
                {children}
            </ContainerDrawer>
        </Drawer>
    );
}