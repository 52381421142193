import styled from "styled-components";

export const ContainerWithMenu = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 250px 1fr;
  transition: 1s padding-left;
  

  /* @media (min-width: 600px) {
    width: calc(100vw - 110px) !important;
    //padding-left: 257px;
  }
   */
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  transition: 1s padding-left;
  

  /* @media (min-width: 600px) {
    width: calc(100vw - 110px) !important;
    //padding-left: 257px;
  }
   */
`;