import styled from "styled-components";

export const Container = styled.div`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const LogoTipo = styled.div`
    height: 71.41px;
    margin: 10px;    
    display: flex;
    justify-content: center;
`
