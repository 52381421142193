
import { useCallback, useEffect, useRef, useState } from "react";
import ComboBoxInput from "../../components/ComboBoxInput";
import { BoxComboDash, BoxHeader, Container, SubContainer, TitleDash } from "./styles";
import { DashBoardItem } from "./types";
import { ReactComponent as LoadingAnime } from "../../assets/svg/animated/load.svg";
import { useApi } from "../../hooks/ApiContext";

export const DataAnalysis: React.FC = () => {
  const initialized = useRef(false);
  const { getDashBoard } = useApi();
  const [allDashs, setAllDashs] = useState<Array<DashBoardItem>>([
    // { label: "Salão de Beleza", value: "Salão de Beleza", url:"https://app.powerbi.com/view?r=eyJrIjoiOWQ3ZWM1MzItZmFmYy00ZjI3LTkyNDUtMDc3NGVhN2IyNzA4IiwidCI6IjAxN2M1MGNhLTA1NTktNDI3YS05MmQwLTA5N2JkYWUwM2Y2MyJ9" }, 
    // { label: "valor 2", value: "val-2", url:"https://www.chartle.com/" }, 
    // { label: "valor 3", value: "val-3" , url:"https://www.chartle.com/" }, 
  ]);
  const [dashSelected, setDashSelected] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDashSelected(event.target.value);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try{
      const response = await getDashBoard();
      const all = response.data.dashboards.map(item => {
        return { label: item.name, value: item.id.toString(), url: item.url };
      });
      if (all.length>0){
        setDashSelected(all[0].value);
      }    
      setAllDashs(all);
    } catch(e)
    {
      console.log(e);
    }

    setLoading(false);
  }, [getDashBoard]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      fetchData();   
    }
  }, [fetchData]);

  return (
    <Container>
      <BoxHeader>
        <BoxComboDash>
          <TitleDash>Dashboard:</TitleDash>
          <ComboBoxInput
            disabled={loading}
            label=""            
            items={allDashs}
            onChange={handleChange}
            value={dashSelected}
          />
        </BoxComboDash>
      </BoxHeader>
      <SubContainer>
        {loading ? <LoadingAnime></LoadingAnime> : null}
        {allDashs.length > 0 ?
          <iframe
            title="myFrame"
            style={{ borderWidth: "0px" }}
            src={allDashs.filter(i => i.value === dashSelected)[0].url}
            height="100%"
            width="100%"
          /> : null}
      </SubContainer>
    </Container>
  );
};
