import { Icon } from "../../Icons";
import { ContainerActive, ContainerInactve, TitleActive, TitleInactive } from "./styles";
import { ButtonMenuProps } from "./types";

export const ButtonMenu: React.FC<ButtonMenuProps> = ({ active, click, icon, children, ...attrs }) => {
  return (
    active ? 
    <ContainerActive onClick={e=>click ? click() : null} {...attrs}>      
      <Icon paddingDefault={true} name={icon} color={"#1090D2"} />
      <TitleActive color={"#1090D2"} >{children}</TitleActive>
    </ContainerActive> :
    <ContainerInactve onClick={e=>click ? click() : null} {...attrs}>      
    <Icon paddingDefault={true}  name={icon} color={"#A5A5A5"} />
    <TitleInactive color={"#A5A5A5"} >{children}</TitleInactive>
  </ContainerInactve> 
  );
};
