import * as yup from "yup";
import { NameErrorYup } from "./types";

export const ListErrorsYup = (erros: any) => {
  const error = erros as yup.ValidationError;
  const allErrors = error.inner.map((item) => {
    return { name: item.path, error: item.errors[0] }
  }).reduce((acumulador: NameErrorYup[], objetoAtual: NameErrorYup) => {
    const existente = acumulador.find((item) => item.name === objetoAtual.name);

    if (!existente) {
      acumulador.push(objetoAtual);
    }

    return acumulador;
  }, []);
  return allErrors;
}

export const HexToRgb = (hex:string) => {
  var bigint = parseInt(hex.replace('#',''), 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return r + "," + g + "," + b;
}