import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-rows: 100px 1fr;
`;

export const SubContainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BoxHeader = styled.div`
    padding: 20px;
`;

export const BoxComboDash = styled.div`
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
`

export const TitleDash = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6E6B7B;
    margin: 0px 0px 15px 0px;    
`