import { useState } from "react";
import { BoxSelect, Container, ContentItem, Item, TitleItem } from "./styles";
import { AccordionProps } from "./types";
import { CheckBox } from "../CheckBox";

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [opens, setOpens] = useState<Array<number>>([]);

  const clickAdd = (idx:number) => {  
    const currently:Array<number> = [...opens];  
    const exists = opens.find((element) => element === idx);
    if (exists !== undefined){
      const index = currently.indexOf(idx);
      currently.splice(index, 1);
    } else {
      currently.push(idx);      
    }
    setOpens(currently);
  }

  const [checkeds, setCheckeds] = useState<Array<number>>([]);
  const clickChecked = (idx:number) => {  
    const currently:Array<number> = [...checkeds];  
    const exists = checkeds.find((element) => element === idx);
    if (exists !== undefined){
      const index = currently.indexOf(idx);
      currently.splice(index, 1);
    } else {
      currently.push(idx);      
    }
    setCheckeds(currently);
  }

  return (
    <Container>
      {items.map((item, index) => {
        return (
          <Item key={index}>
            <TitleItem>
              <div style={{    width: "calc(100% - (28px + 45px))"}} >
                <CheckBox onChecked={()=>clickChecked(index)} text={item.label} checked={checkeds.find((element) => element === index) !== undefined} />
              </div>
              <BoxSelect onClick={()=>clickAdd(index)} style={{transform: opens.find((element) => element === index) !== undefined ? "rotate(0deg)" : "rotate(180deg)"}}/>
            </TitleItem>
            { opens.find((element) => element === index) !== undefined ? <ContentItem>{item.render()}</ContentItem>:null}
          </Item>
        )
      })}
    </Container>
  );
};
