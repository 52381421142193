
import { BoxConnection } from "../../components/BoxConnection";
import { BoxLoading, Container, ContainerColumn } from "./styles";
import { useRouterParams } from "../../hooks/router-custom";
import { useApi } from "../../hooks/ApiContext";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Connectors } from "./types";
import { ReactComponent as LoadingAnime } from "../../assets/svg/animated/load.svg";
import { TabBar } from "../../components/TabBar";
import { BoxManagerConnects } from "../../components/BoxManagerConnects";
import { DetailApplicationFromCompanyOriginal } from "../../api/responses/types";
import { BoxManagerConfiguration } from "../../components/BoxManagerConfiguration";

export const ManageConnection: React.FC = () => {  
  const { getApplicationStorage, getDetailApplication, getDetailConnector } = useApi();    
  const nameConnection = useRouterParams('connetion');
  const [allConnectors, setAllConnectors] = useState<Connectors[]>();
  const [applicationCompany, setApplicationCompany] = useState<DetailApplicationFromCompanyOriginal>();
  const [loading, setLoading] = useState<boolean>(false);
  const initialized = useRef(false);
  
  const boxConnection = () => {
    let connector = { image:'',name:'',category:'',color:'' };
    const all = getApplicationStorage();
    const exists = all.filter(f => f.name === nameConnection);
    if (exists && exists.length>0){
      connector.image = String(exists[0].brand);
      connector.name = String(exists[0].name);
      connector.category = String(exists[0].category);
      connector.color = String(exists[0].color);
    }
    return (
      <BoxConnection
        image={connector.image}
        title={connector.name}
        tag={{text:connector.category,color:String(connector.color)}}
        inactive={true}
      />
    )
  }

  const BoxConnectionMemo = memo(boxConnection);

  const getDetailsValues = useCallback(async () => {
    let connector = { id:0 };
    const all = getApplicationStorage();
    const exists = all.filter(f => f.name === nameConnection);
    if (exists && exists.length>0){
      connector.id = exists[0].id;
    }
    const processingApplication = getDetailApplication(connector.id.toString());
    const processingConnector = getDetailConnector(connector.id.toString());
    Promise.all([processingApplication, processingConnector]).then(([responseApplication, responseConnector]) => {
      setApplicationCompany(responseApplication.original);
      setAllConnectors(responseConnector.original.data);
      setLoading(false);
    });
  }, [getDetailApplication,getApplicationStorage,getDetailConnector,nameConnection]);   

  useEffect(() => {    
    if (!initialized.current) {
      initialized.current = true
      setLoading(true);
      getDetailsValues(); 
    }
  // eslint-disable-next-line        
  }, []); 

  return (
    <Container>
      {loading ? <BoxLoading><LoadingAnime/></BoxLoading>: null}
      <BoxConnectionMemo />
      <ContainerColumn>        
      <TabBar items={
        [
          {
            label: 'Conectores',
            render: (index: number) => {
              return <BoxManagerConnects connectors={allConnectors?allConnectors:[]} nameConnection={nameConnection} />
            }
          },
          {
            label: 'Configurações',
            render: (index: number) => {
              return <BoxManagerConfiguration vars={applicationCompany?.application_vars} />
            }
          },
          {
            label: 'Autenticação',
            render: (index: number) => {
              return <></>
            }
          }
        ]
      }></TabBar>
      </ContainerColumn>
    </Container>
  );
};
