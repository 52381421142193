import { Button } from "@mui/material";
import { SubContainerLogin, ContainerLogin, Title } from "./styles";
import { BoxMainLoginProps } from "./types";
import TextBoxInput from "../TextBoxInput";
import { useEffect, useState } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import { RoutesFamilyEnum, useRouterFamilyNavigation } from "../../hooks/router-custom";
import { useApi } from "../../hooks/ApiContext";

export const BoxMainLogin: React.FC<BoxMainLoginProps> = () => {
  const { GetApplicationsByEmail, slug } = useApi();
  const navigate = useRouterFamilyNavigation();

  const [createSuccess, setCreateSuccess] = useState(false);
  const [validating, setValidating] = useState(false);  
  const [email, setEmail] = useState('');

  const enter = async () => {    
    try{
      setValidating(true);
      const response = await GetApplicationsByEmail(email);
      if (response){        
        setCreateSuccess(true);    
      }else{
        setValidating(false);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const createAccount = () => navigate(RoutesFamilyEnum.CreateAccount);

  useEffect(()=>{
    if (createSuccess){
      navigate(RoutesFamilyEnum.Login);
    }    
  },[slug,createSuccess,navigate]);

  return (
    <ContainerLogin>
      <SubContainerLogin>
        <Title className='h1-title'>Encontre Sua Conta  🔍</Title>

        <TextBoxInput
          label="Email"
          onChange={e => setEmail(e.target.value)}
          type="email"
          value={email}
          subLabel=""
          placeHolder="john@gmail.com"
        />

        <LoadingButton
            loading={validating}
            loadingPosition="end"
            disabled={validating || email === ""}
            variant="contained"
            sx={{ my: 2, textTransform: 'none' }}
            style={{ background: '#1090D2', width: '100%' }}
            type="submit"
            onClick={enter}
            fullWidth
          >
            {validating ? "Aguarde..." : "Entrar"}
          </LoadingButton>        

        <Button
          sx={{ textTransform: 'none' }}
          style={{ width: '100%' }}
          variant="outlined"
          onClick={createAccount}
        >
          Cadastre-se
        </Button>
      </SubContainerLogin>
    </ContainerLogin>
  );
};
