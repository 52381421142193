import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    margin-bottom: 20px;
`;

export const Title = styled.p`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6E6B7B;
    margin: 0px 0px 3px 0px;    
`
export const ContainerButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ContainerImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;